.form-control {
  border-radius: 8px;
  border: 1px solid #AbA3A3;
  background-color: var(--whiteBG) !important;
  padding: 13px !important;
}

.form-control:disabled {
  background-color: rgb(0 0 0 / 5%) !important;
  opacity: 1 !important;
  color: #a7a7a7 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.addItem-head {
  font-size: 24px;
  font-weight: 600;
}

.activecls {
  background: var(--whiteBG);
}

.form-label {
  color: var(--black);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.28px;
}

.paddss {
  padding: 0px 50px;
}

.addItem-btn {
  background-color: var(--background);
  color: var(--whiteBG);
  font-size: 18px;
  border: none;
  padding: 10px 40px;
  border-radius: 10px;
  justify-content: center;
  display: flex;
  cursor: pointer !important;
}

.HandOverbtn {
  background-color: var(--background);
  color: var(--whiteBG);
  font-size: 20px;
  border: none;
  padding: 10px;
  border-radius: 10px;
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  font-weight: 400;
  cursor: pointer;
}

.end {
  justify-content: end;
  display: flex;
}

.creatBox {
  border-radius: 12px;
  border: 1px solid #ECEEF1;
  background: var(--whiteBG);
  box-shadow: var(--shadow);
  padding: 20px 30px;
}

.orngbox {
  cursor: pointer;
  border-radius: 12px;
  border: 1px solid var(--background);
  background: var(--whiteBG);
  padding: 30px;
}

.img-cartoon {
  height: 50px;
}

.selectedadd {
  border-radius: 8px;
  background: var(--whiteBG);
  color: var(--black);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
}

.categ {
  border-radius: 8px;
  border: 1px solid var(--background);
  background: var(--whiteBG);
  color: var(--background);
  font-size: 14px;
  font-weight: 500;
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addbx {
  background-color: var(--whiteBG);
  border: 1px solid #ABA3A3;
  padding: 10px 20px;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
}

.txrea {
  height: 80px;
}

.texthead {
  color: var(--Body-text, #2D3748);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.28px;
}

.headtext {
  color: var(--black);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.36px;
  justify-content: center;
  display: flex;
  padding: 10px;
}

.maoin-BG {
  border-radius: 8px;
  background: var(--whiteBG);
  box-shadow: var(--shadow);
  padding: 10px;
}

/* create Add on */

.dynoTable tr th {
  padding: 20px;
}

.dynoTable {
  border-radius: 10px 10px 0px 0px;
}

td .css-b62m3t-container .css-13cymwt-control {
  width: 204px;
  margin: 0;
  padding: 3px !important;
  border-radius: 0px !important;
  border: var(--border) !important;
}

td .css-b62m3t-container .css-13cymwt-control .css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

td .css-1nmdiq5-menu {
  width: 220px !important;
}

td .css-b62m3t-container .css-1nmdiq5-menu {
  width: 220px !important;
}

td .css-b62m3t-container .css-t3ipsp-control {
  width: 220px !important;
}

/* Media Queriess  Start*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

  .pad-top {
    padding-top: 100px !important;
  }

  .w-25 {
    width: 75% !important;
  }

  .paddss {
    padding: 0px;
  }

  .w-75 {
    width: 100% !important;
  }

  .addItem-btn {
    width: 100%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media screen and (min-width: 768px) and (max-width: 1024px) {

  .w-25 {
    width: 85% !important;
  }

  .w-75 {
    width: 80% !important;
  }


  .categ {
    padding: 5px 10px;
    font-size: 10px;
  }

  .headtext {
    font-size: 10px;
  }
}

@media screen and (max-width: 1024px) and (max-height: 1024px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

  .w-75 {
    width: 84% !important;
  }
}

@media (width: 1024px) {
  .rows {
    flex-direction: column;
  }

  .creatBox {
    margin-bottom: 20px;
  }

  .col6 {
    width: 100%;
  }

  .w-25 {
    width: 45% !important;
  }

  .col4 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1279px) {
  .categ {
    padding: 5px 10px;
    font-size: 11px;
  }

  .headtext {
    font-size: 14px;
  }

  .texthead {
    font-size: 12px;
  }


}

@media only screen and (min-width:1280px) and (max-width:1368px) {
  .categ {
    padding: 5px 10px;
  }

  .headtext {
    font-size: 14px;
  }
}

@media only screen and (min-width:1441px) and (max-width :1600px) {
  .categ {
    padding: 5px 10px;
  }

  .headtext {
    font-size: 14px;
  }
}

@media only screen and (min-width:1600px) and (max-width :1800px) {}

@media only screen and (width: 1024px) {}

@media only screen and (min-width:1441px) {}

@media only screen and (width: 1440px) {
  .categ {
    padding: 5px 10px;
  }
}
@media only screen and (width: 1024px) {}

:root {
  --background: #5B93FF;
  --border: 1px solid #5C5E64;
  --color: #5B93FF;
  --whiteBG: #fff;
  --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
  --black: #231F20;
}