@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}


body {
    font-family: "Urbanist", sans-serif;
    font-optical-sizing: auto;
    background-color: #F5F5F5;
}

/* Track */
::-webkit-scrollbar-track {
    background: var(--whiteBG);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #8888886c;
}

.mainInvHead {
    font-size: large;
    font-weight: 600;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #6E5199;
}



.slide-container {
    border-radius: 8px 8px 0 0;
    border: var(--border);
    background: var(--whiteBG);
}

.mainbtn {
    background-color: #6E5199;
    color: #F5F5F5;
    font-size: 16px;
    border: none;
    padding: 5px 25px;
    border-radius: 5px;
    justify-content: center;
    display: flex;
    cursor: pointer;
}

.mainbtn:hover {
    background-color: #826ca1;
}

.mainbtnRed {
    background-color: #DC3545;
    color: #F5F5F5;
    font-size: 16px;
    border: none;
    padding: 5px 25px;
    border-radius: 5px;
    justify-content: center;
    display: flex;
    cursor: pointer;
}

.mainbtnRed:hover {
    background-color: #e7616d;
}

.gridbtn {
    justify-content: center;
    border: var(--border);
    border-radius: 15px;
    background: #313a46;
    display: flex;
    color: var(--whiteBG);
    align-items: center;
    text-align: center;
    font-size: 14px;
    height: 30px;
    font-weight: 400;
    width: 130px;
}

.gridDisab {
    background-color: #a6afb6;
    border-radius: 30px;
    height: 30px;
    font-size: 14px;
    width: 130px;
    color: var(--whiteBG);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.mainbtnGry {
    background-color: #313a46;
    color: #F5F5F5;
    font-size: 16px;
    border: none;
    padding: 5px 20px;
    border-radius: 5px;
    justify-content: center;
    display: flex;
    cursor: pointer;
}

.mainbtnSecondary {
    background-color: #6c757d;
    color: #F5F5F5;
    font-size: 16px;
    border: none;
    padding: 5px 25px;
    border-radius: 5px;
    justify-content: center;
    display: flex;
    cursor: pointer;
}

.ag-row {
    height: 35px !important;
}

.form-check-input:checked {
    background-color: #6E5199 !important;
    border-color: #6E5199 !important;
}

.gridBg {
    background-color: var(--whiteBG);
    border: 1px solid #c7c7c7;
    border-radius: 16px 16px 0 0;
    padding: 20px;
}

.gridshad {
    border: 1px solid #c7c7c7;
    box-shadow: var(--shadow);
}

.form-switch .form-check-input {
    height: 18px !important;
    width: 32px !important;
}

.ag-row:hover {
    background-color: #D3CAE0 !important;
}

.ag-row-hover {
    background-color: #D3CAE0 !important;
}

.ag-row-hover:hover {
    background-color: #D3CAE0 !important;
}

.brderbox {
    border-radius: 8px;
    border: var(--border);
    background: var(--whiteBG);
}

.file-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: center;
}

.brderbox2 {
    border-radius: 0px 0px 10px 10px;
    border: var(--border);
    background: var(--whiteBG);
}

.cost-center-section {
    width: 100%;
    overflow: visible;
}

.slide-header {
    background-color: var(--whiteBG);
    border: 1px solid #c7c7c7;
    border-radius: 8px 8px 0 0;
    padding: 10px;
    cursor: pointer;
    box-shadow: var(--shadow);
    transition: background-color 0.5s ease;
}

.slide-header:hover {
    background-color: #e2e6ea;
}

.start {
    display: flex !important;
    justify-content: start !important;
    align-items: start !important;
    text-align: start !important;
}

.slide-content {
    overflow: hidden;
    transition: max-height 0.5s ease;
    max-height: 0;
}

.slide-content.open {
    max-height: 1000px;
}

.border-box2 {
    background-color: var(--whiteBG);
    border: 1px solid #d4d4d4;
    /* border-radius: 0 0 8px 8px; */
}

.border-box {
    background-color: var(--whiteBG);
    border: var(--border);
    border-radius: 8px;
    filter: drop-shadow(0px 4px 4px rgba(0.6716145873069763, 0.6374742984771729, 0.6374742984771729, 0.38999998569488525));
}

.form-label {
    font-weight: 500;
    min-width: 120px;
}

@media (max-width: 768px) {
    .form-group .col-md-4 {
        flex-basis: 100%;
        max-width: 100%;
        margin-bottom: 1rem;
    }

    .form-label {
        min-width: 100px;
    }
}

.dynoTable tr th {
    padding: 10px;
    background-color: #6E5199;
    color: var(--whiteBG);
}



.InvProfile {
    border-radius: 8px;
    /* background: #6E5199; */
    padding: 10px 25px 10px 0px;
    color: var(--whiteBG);
    font-size: 16px;
    font-weight: 600;
    color: #000;
}

.pagclr {
    color: #6E5199;
}

:root {
    /* --background: #5B93FF; */
    /* --background: #D79031; */
    --border: 1px solid #5C5E64;
    --color: #6E5199;
    /* --color: #5B93FF; */
    --whiteBG: #ffffff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
}