.InActiveTabs{
    color: #5C5E64;
    border: 1px solid #6E5199;
    padding: 5px 20px;
    border-radius: 10px;
}
.subactive{
    background-color: #C098E5;
    color: #fff;

}
.form-label {
    font-weight: 500;
    /* min-width: 20px; */
    color: #5C5E64;
    
}
.bg-blue{
    background-color: #EFE1FC;

}
thead .bg-purple th{
    background-color: #6E5199;
    color: #fff;
    border: none;

    padding: 13px 20px !important;
}
.textArea{
    background: #fcfcfc;
    border-radius: 14px;
}
/* recipeAllergens.css */
.allergens-container {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.allergens-table {
    border-collapse: collapse;
    width: 100%;
}

.allergens-table th, .allergens-table td {
    border: 1px solid #ddd; /* Add borders to table cells */
    padding: 12px;
    text-align: center;
}

.allergens-table th {
    background-color: #6a1b9a;
    color: #fff;
}

.allergens-table td {
    vertical-align: middle;
}

.input-description,
.input-remarks {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    border: none;
}

.form-check-input {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.add-row-btn {
    display: inline-block;
    background-color: #6a1b9a;
    color: #fff;
    font-size: 14px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.add-row-btn:hover {
    background-color: #50177b;
}


.allergens-table {
    border-collapse: collapse;
    width: 100%; /* Full width */
    margin: 0 auto;
    border: 1px solid black; /* Black border for the table */
    table-layout: fixed; /* Fixed column widths */
}

.allergens-table th,
.allergens-table td {
    border: 1px solid black; /* Black border for cells */
    text-align: left;
    padding: 2px; /* Reduced padding to make the rows less tall */
    height: 15px; /* Explicitly set a smaller row height */
    line-height: 16px; /* Adjust line height for text */
}

/* First column for editing icon */
.allergens-table th:first-child,
.allergens-table td:first-child {
    width: 5%; /* Smaller width for the first column */
    text-align: center;
}

/* Equal width for other columns */
.allergens-table th:nth-child(2),
.allergens-table th:nth-child(3),
.allergens-table th:nth-child(4),
.allergens-table td:nth-child(2),
.allergens-table td:nth-child(3),
.allergens-table td:nth-child(4) {
    width: 31%;
}

/* Editing icon size */
.editing-icon {
    width: 16px;
    height: 16px;
}

/* Header styles */
.allergens-table th {
    background-color: #6c5ce7; /* Purple background for header */
    color: white;
    text-align: center;
    padding: 2px; /* Reduced padding in header */
    height: 24px; /* Smaller header height */
    line-height: 20px; /* Reduced line height */
}
