.form-check-input:checked {
    background-color: var(--background) !important;
    border-color: #ffffff !important;
}
:root {
    --background: #5B93FF;
    --border: 1px solid #5C5E64;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
}