.CartName {
    color: var(--black);
    font-size: 24px;
    font-weight: 600;
}

.cartPrice {
    color: var(--black);
    font-size: 18px;
    font-weight: 700;
}

.checkItem {
    color: var(--black);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
}

.checkItem2 {
    color: var(--black);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
}

.lineBreak {
    border-bottom: 1px solid #D4D9DE;
}

.special {
    color: #212529;
    font-size: 18px;
    font-weight: 500;
}

.textarea {
    border-radius: 8px;
    border: 1px solid #CED4DA;
    background: #FDFDFD;
    width: 100%;
}

.textarea:focus {
    border: 1px solid #CED4DA;
}

.form-check-input:checked {
    background-color: var(--background);
    border-color: var(--background);
}

.SubItem {
    border-radius: 13px;
    background: #BFBFBF;
    border: none !important;
    height: 45px;
    width: 45px;
    color: #FDFDFD;
    align-items: center;
    display: flex;
    justify-content: center;
}

.addItem {
    border-radius: 13px;
    background: var(--background);
    border: none !important;
    height: 45px;
    width: 45px;
    color: #FDFDFD;
    align-items: center;
    display: flex;
    justify-content: center;
}

.AddBtn {
    border-radius: 8px;
    background: var(--background);
    border: none !important;
    color: #fff;
    padding: 10px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}

.ReactModal__Overlay {
    background-color: rgba(61, 61, 61, 0.364) !important;
    position: fixed !important;
}

.ReactModal__Content {
    width: 75%;
    margin: 0px auto;
}


/* Media Queriess  Start*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .mbl-display {
        display: none !important;
    }

    .CartName {
        font-size: 18px;
    }

    .cartPrice {
        font-size: 14px;
    }

    .addItem {
        width: 90px;
    }

    .SubItem {
        width: 90px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media screen and (min-width: 768px) and (max-width: 1024px) {}

/* Medium devices (landscape tablets, 1024px and up) */
@media screen and (max-width: 1024px) and (max-height: 1024px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}

:root {
    --background: #5B93FF;
    --border: 1px solid #5C5E64;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
    --blackBG: #11263aad;
}