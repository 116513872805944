.invprofileimg {
    /* position: absolute; */
    top: 5px;
    left: 10px;
    border: 4px solid #fff;
    border-radius: 34px;
}

.mainBG {
    background: #fbfbfb;
    padding: 20px;
    /* box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.10); */
}

.mainhd {
    background: #fbfbfb;
    padding: 20px;
}

.Invboxicon {
    background: #dadada;
    color: #6E5199;
    height: 32px;
    width: 32px;
    object-fit: cover;
    border-radius: 30px;
}