.topnav {
    border-radius: 12px;
    border: 1px solid #5C5E64;
    text-decoration: none !important;
}

.topnav2 {
    border-radius: 12px 12px 0px 0px;
    border: 1px solid #5C5E64;
    text-decoration: none !important;
}

.linkbox {
    border-radius: 8px;
    border: 1px solid #5C5E64;
    padding: 10px;
}

.overslid {
    text-decoration: none !important;
}

.sidebar-text {
    font-weight: 600;
    font-size: 18px;
    color: #747681;
    text-decoration: none !important;
    cursor: pointer;
}

/* create user */
.passbx {
    background-color: rgba(217, 217, 217, 0.44999998807907104);
    border-radius: 12px;
    padding: 20px;
    display: flex;
    justify-content: center;
}

.otpbx {
    background-color: #ffffff;
    height: 40px;
    width: 40px;
    border-radius: 5px;
    border: 1px solid #6E5199;
    margin: 5px;
    text-align: center;
    padding: 10px;
}

.sideActive {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    padding: 8px 30px;
    border-radius: 36px;
    background-color: #6E5199 !important;
    color: var(--whiteBG) !important;
}

.bx {
    background-color: rgba(217, 217, 217, 0.25);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    padding: 10px;
    width: auto;
    border: 0.1px solid #dddde0;
}

:root {
    /* --background: #D79031; */
    --border: 1px solid #d2cfcfbc;
    --color: #6E5199;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
    --blackBG: #11263aad;
}