.tagfont {
    color: #FFF;
    text-align: center;
    font-size: 8px;
    font-weight: 500;
    border-radius: 0px 5px 5px 0px;
    background: var(--background);
    position: absolute;
    padding: 4px 15px;
    margin-top: 10px;
}

.ActiveTag {
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    border-radius: 15px 15px 0px 0px;
    background: #dc3545;
    padding: 12px 25px;
}

.menu-item-card {
    border: 1px solid #d2cfcfbc;
    border-radius: 15px;
    cursor: pointer;
}

.menu-item-card:hover {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
}

.min-top {
    margin-top: 35px;
}

.ItemName {
    color: #3F3F3F;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
}

.IMG-BG {
    border-radius: 15px 15px 0px 0px;
    background: #EFEFEF;
}

.main-color {
    background: #FFF;
    border-radius: 0px 0px 15px 15px;
}

/* Media Queries */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1024px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}

:root {
    --background: #5B93FF;
    --border: 1px solid #d2cfcfbc;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
    --blackBG: #11263aad;
}