.bg {
    background: #FFF;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    padding: 15px;
}

.KDCprofileimgbox {
    position: absolute;
    top: 10px;
    right: 40px;
    border: 4px solid #fff;
    border-radius: 34px;
}

/* Media Queries */

/* Extra small devices (phones, 600px and down) */


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width : 768px){
    .KDCprofileimgbox {
        top: 38px !important;
        right: 10px !important;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1024px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}