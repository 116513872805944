@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

.body {
    /* font-family: 'poppins-bold', sans-serif !important; */
    font-family: 'Poppins', sans-serif;
}

body::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

body::-webkit-scrollbar-thumb {
    background-color: #888;
}

body::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.lgintx {
    font-size: 16px;
    font-weight: 500;
    color: var(--background);
}

.logo_size {
    height: auto;
    width: 20% !important;
}

.centrlogo {
    height: auto;
    width: auto !important;
}

.col6-BG {
    background-color: var(--background);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.input-group-text {
    padding: 1.3rem 0.75rem;
    background-color: var(--background);
    color: var(--whiteBG);
    border: var(--bs-border-width) solid #aba3a3;
}

.inventoryBox {
    background-color: var(--whiteBG);
    border-radius: 12px;
    padding: 30px 100px;
}

.textin {
    font-size: 30px;
    font-weight: 600;
    color: var(--black);
}

.login_head {
    color: #043133 !important;
    font-size: 48px;
    font-weight: bold;
    font-family: 'poppins';
}

.Button_Normal {
    background-color: var(--background) !important;
    color: var(--whiteBG) !important;
    width: 39%;
    font-size: 24px;
}

.center {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;
}

.align_center {
    justify-content: center !important;
    text-align: center;
}

.orange_text {
    color: var(--background) !important;
    font-size: 14px;
    text-decoration: none;
}

.simple_mini {
    color: #4D5959;
    font-size: 14px;
}

.border_simple {
    border: 1px solid #D2D2D2;
    padding: 12px;
    border-radius: 10px;
    text-decoration: none;
}

.icons {
    height: auto;
    width: 14% !important;
    padding-right: 10px;

}

.Auth_Btn {
    color: #043133;
    font-size: 14px;
}

.head2 {
    font-size: 24px;
    color: #043133;
}

.labels {
    font-size: 14px;
}

.form-check-input:checked {
    background-color: var(--background);
    border-color: var(--background);
}

.btn-secondary {
    background: var(--background) !important;
    border: 1px solid var(--background) !important;
}

.btn-primary {
    background: var(--whiteBG) !important;
    border: 1px solid var(--background) !important;
    color: var(--background) !important;
}

.btn-secondary:hover {
    background: var(--whiteBG) !important;
    border: 1px solid var(--background) !important;
    color: var(--background) !important;
}

.btn-primary:hover {
    background: var(--background) !important;
    border: 1px solid var(--background) !important;
    color: var(--whiteBG) !important;
}

:root {
    --background: #5B93FF;
    --border: 1px solid #5C5E64;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
}