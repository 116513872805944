.bghead {
    background: #F3F3F3;
    padding: 15px;
    border-radius: 8px;
}

.refbtn {
    border-radius: 8px;
    background: var(--background);
    padding: 10px;
    cursor: pointer;
    color: #F3F3F3;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
}


.css-t3ipsp-control {
    border-color: var(--background) !important;
    box-shadow: 0 0 0 1px var(--background) !important;
}

.css-tr4s17-option {
    background-color: var(--background) !important;
}



.css-d7l1ni-option:active {
    background-color: var(--background)59 !important;
}

.css-d7l1ni-option:hover {
    background-color: var(--background)59 !important;
}

.section-contain {
    padding: 10px;
    background-color: #f4f4f4;
    border-radius: 12px;
    border: 1px solid var(--background);
}

.status-contain {
    border-radius: 8px;
    background: var(--background);
    color: #FFF;
    font-size: 18px;
    font-weight: 600;
    padding: 15px;
}

.status-head {
    border-radius: 12px;
    background: var(--background);
    padding: 10px;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: space-between;
}

.orderbox {
    padding: 10px;
    background: #FFF;
    min-height: 200px;
    overflow: auto;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15000000596046448));
    border-radius: 12px;
    border: 1px solid var(--background);
}

.void {
    background-color: #ffffff;
    border-radius: 5px !important;
    border: 1px solid #d10606;
    padding: 10px;
}

.odrtbl {
    background-color: #ffffff;
    border-radius: 5px !important;
    border: 1px solid var(--background);
    padding: 10px;
}

.addbrdr {
    border-top: none;
}

.separators {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 10px 0;
}

.separators hr {
    flex-grow: 1;
    border: none;
    height: 1px;
    background-color: #000000;
    margin: 0 10px;
}

.separators div {
    height: 20px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.status-Pre {
    border-radius: 8px;
    background: #D8584D;
    color: #FFF;
    font-size: 18px;
    font-weight: 600;
    padding: 15px;
}

.status-ready {
    border-radius: 8px;
    background: #B74D77;
    color: #FFF;
    font-size: 18px;
    font-weight: 600;
    padding: 15px;
}

.status-done {
    border-radius: 8px;
    background: #7E5288;
    color: #FFF;
    font-size: 18px;
    font-weight: 600;
    padding: 15px;
}

.status-cancel {
    border-radius: 8px;
    background: #475279;
    color: #FFF;
    font-size: 18px;
    font-weight: 600;
    padding: 15px;
}

@media (width: 1024px) {
    .rows {
        flex-direction: column;
    }

    .col6 {
        width: 100%;
    }

    .status-contain {
        font-size: 14px;
    }

    .col4 {
        flex: 0 0 auto;
        width: 100%;
    }
}

:root {
    --background: #5B93FF;
    --border: 1px solid #5C5E64;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
}