.uploadBox {
    overflow: hidden;
}

.uploadBox .drag-area {
    border-radius: 5px;
    border: 2px dashed #5B93FF;
    background-color: #fff;
    user-select: none;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    -webkit-user-select: none;
    cursor: pointer;
}

.mainbox {
    border-radius: 12px;
    border: 1px solid #5B93FF;
    background: #FFF;
}

.uploadBox .drag-area .visible {
    font-size: 18px;
}

.uploadBox .select {
    cursor: pointer;
    transition: 0.4s;
}

.uploadBox .select:hover {
    opacity: 0.6;
}

.uploadBox .container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
    flex-wrap: wrap;
    margin-top: 10px;
    max-height: 200px;

}

.uploadBox input,
.uploadBox .drag-area .on-drop,
.uploadBox .drag-area.dragover .visible {
    display: none;
}

.delete {
    z-index: 999;
    color: #5B93FF;
}

.uploadBox .container .image {
    width: 100px;
    margin-right: 15px;
    height: 100px;
    position: relative;
    margin-bottom: 8px;
}

.uploadBox .container .image img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
}

.uploadBox .container .image span {
    position: absolute;
    top: -2px;
    right: 9px;
    font-size: 20px;
    cursor: pointer;
}


/* Media Queriess  Start*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .uploadBox {
        padding: 0px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .uploadBox {
        padding: 20px 0px 20px 0px;
    }
}

@media screen and (max-width: 1024px) and (max-height: 1024px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}