.circle {
    border-radius: 11px;
    background: var(--background);
    width: 22px;
    height: 22px;
    display: inline-block;
}

.iconimg {
    height: 100px;
    width: auto;
    object-fit: fill;
}

.txtali {
    text-align: center;
    align-items: center;
}

.dottim {
    opacity: 0%;
}

.maintxt {
    color: #5B93FF;
    font-size: 18px;
    font-weight: 500;
}

.smltxt {
    color: var(--natural-500, #667085);
    font-size: 14px;
    font-weight: 400;
}

.RSPBprogressBar .RSPBstep {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    position: absolute !important;
    transform: translateX(-50%);
    transition-property: all;
    transition-timing-function: ease;
}

.RSPBprogressBar {
    height: 10px;
    /* line-height: 1; */
    border-radius: 10px;
    position: relative;
    background-color: rgba(211, 211, 211, 0.6);
    display: flex;
    justify-content: start !important;
    align-items: center;
    z-index: 0;
}


.circleActive {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px;
    background: linear-gradient(135deg, var(--background) 38.54%, #2F4CDD 100%) !important;
    color: #FFFCFC !important;
}

@media (width: 1024px) {

    .maintxt {
        font-size: 12px;
    }

    .circle {
        width: 20px;
        height: 20px;
    }

    .circleActive {
        width: 20px;
        height: 20px;
    }
}

:root {
    --background: #5B93FF;
    --border: 1px solid #d2cfcfbc;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
    --blackBG: #11263aad;
  }