.adminprofileimgbox {
    position: absolute;
    top: 18px;
    right: 213px;
    border: 4px solid #fff;
    border-radius: 34px;
}

.mainBG {
    background: #fbfbfb;
    padding: 20px;
    /* box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.10); */
}

.boxicon {
    background: #C4C4C4;
    padding: 12px;
    color: var(--background);
}

.Headtxt {
    font-size: 1.5rem;
    font-weight: bold;
}

.headtxt2 {
    font-size: 1rem;
    color: #888;
}

.adminImg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.iconBG {
    border-radius: 8px;
    background: #E9ECFF;
    padding: 10px;
    align-items: center;
    display: flex;
}

.pad {
    padding-right: 200px;
}

@media (width: 1024px) {

    .Headtxt {
        font-size: 24px !important;
    }
}
:root {
    --background: #5B93FF;
    --border: 1px solid #5C5E64;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
}
