.Heads {
  color: var(--black);
  font-size: 28px;
  font-weight: bold;
}

.SilverHeads {
  color: #969ba0;
  font-size: 18px;
}

.card-text {
  color: var(--black);
  font-size: 40px;
  font-weight: 600;
}

.card-title {
  color: #3E4954;
  font-size: 16px;
  font-weight: 500;
}

.card {
  border: none;
  box-shadow: var(--shadow);
}

.DashIcon {
  width: 125%;
}

.decor_n {
  text-decoration: none;
}

.miniLabels {
  font-size: 18px;
  color: #828282;
  font-weight: bold;
}

.Summary-label {
  font-size: 20px;
  font-weight: 500;
  color: var(--black);
}

.Summary-mini {
  color: #969BA0;
  font-size: 12px;
  font-weight: 400;
}

.count-itself {
  color: var(--whiteBG);
  font-size: 22px;
  padding-left: 25px;
  padding-right: 25px;
}

.bg-counts {
  background-color: var(--background);
  border-radius: 10px;
}

.bg-main-count {
  background-color: #e9ffef;
}

.ord-in-que {
  color: var(--black);
  font-size: 18px;
  font-weight: bold;
}

.green-mini {
  color: var(--background);
  font-size: 16px;
  font-weight: 500;
}

.end-center {
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.arrow-right {
  padding-left: 20px;
}

.summary-head {
  color: #3e4954;
  font-size: 32px;
  font-weight: bold;
}

.summary-para {
  color: #969BA0;
  font-size: 18px;
  font-weight: 500;
}

.dlvr {
  color: #3E4954;
  font-size: 14px;
  font-weight: 500;
}

.search-icon {
  position: absolute;
  right: 0;
  padding: 7px;
  display: flex;
  margin-right: 10px;
}

.border-none {
  border: 1px solid #ebebeb !important;
}

.category-item {
  border: none;
  font-size: 16px;
  background: none !important;
  color: #2C2F24;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

.category-head {
  font-size: 23px;
  font-weight: 500;
}

.bg-orange {
  background-color: rgb(255, 109, 76);
}

.bg-main {
  background-image: url('../../assets/images/svgIcons/bgMain.svg');
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  display: flex;
  justify-content: center;
}

.inner-body {
  background-color: var(--whiteBG);
  border-radius: 14px;
  text-align: center;
  justify-content: center;
}

.button {
  border: none;
  background-color: var(--whiteBG);
  padding: 5px 10px;
  cursor: pointer;
  font-size: 15px;
}

.button.active {
  border: none;
  background-color: var(--background);
  border-radius: 15px;
  font-weight: bold;
  color: var(--whiteBG) !important;
  font-size: 15px;

}

.letsGo-btn {
  background-color: var(--background);
  color: var(--whiteBG);
  font-size: 18px;
  font-weight: bold;
  border-radius: 10px;
  padding: 12px;
  cursor: pointer;
}

.linear-box {
  border-radius: 12px;
  background: linear-gradient(156deg, #5B93FF 15.63%, #2f4cdd 116.26%);
  color: var(--whiteBG);
  font-size: 12px;
  text-align: center;
  align-content: center;
  position: absolute;
  width: 150px;
}

.w-40 {
  width: 30%;
}


.card-radious {
  border-radius: 20px;
  background: var(--whiteBG);
  box-shadow: var(--shadow);
}

:root {
  --background: #5B93FF;
  --border: 1px solid #5C5E64;
  --color: #5B93FF;
  --whiteBG: #fff;
  --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
  --black: #231F20;
}