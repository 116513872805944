.itemSpacing {
    gap: 25px !important;
    display: flex;
    overflow: auto;
}

.profilestyle a {
    text-decoration: none;
    font-weight: 500;
    font-size: 13px !important;
    color: #747681;
    justify-content: space-between;
    padding: 5px !important;
}

.TxtOverFlow {
    display: flex;
    gap: 35px !important;
    overflow: hidden;
}

.offerbox {
    border: 1px solid #CDD6D7;
    border-radius: 22px;
    height: auto;
    width: 100%;
}


.settingtext {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: var(--white);
}

.inputfield {
    height: 63px;
    border-radius: 22px;
    border: 1px solid #CDD6D7;
}

.savebtn {
    width: 133px;
    height: 54px;
    background-color: #1E344E;
    border-radius: 100px;
}

.themebtn {
    height: 54px;
    width: 100%;
    border-radius: 22px;
    background: #FFFFFF;
    font-weight: 600;
    font-size: 15px;
    border: 1px solid #000000;
}

.themedarkbtn {
    height: 54px;
    width: 100%;
    border-radius: 22px;
    background: #1E344E;
    font-weight: 600;
    border: 1px solid #FFFFFF;
    font-size: 15px;
    color: #FFFFFF;
}

.supportdropyxt {
    font-weight: 500;
    font-size: 18px;
    color: #747681;
}

.spacebetween {
    justify-content: space-between;
}

.solidLine {
    border-top: 1px solid #CDD6D7;
}

.offerbtn {
    height: 54px;
    width: 100%;
    background: #747681;
    border-radius: 22px;
    font-weight: 600;
    font-size: 18px;
    color: #FFFFFF;
}

.centertxt {
    line-height: 35px;
    font-size: larger;
    text-align: center;
}

.previewbtn {
    height: 54px;
    width: 100%;
    background: #000000;
    border-radius: 22px;
    font-weight: 600;
    font-size: 18px;
    color: #FFFFFF;
}

.Deletebtn {
    height: 46px;
    width: 100%;
    background: #1E344E;
    border-radius: 50px !important;
    border: none;
    font-size: 15px;
    color: #FFFFFF;
}

.notificationhead {
    font-weight: 600;
    font-size: 18px;
    color: var(--white);
}

.notificationtxt {
    font-weight: 400;
    font-size: 16px;
    color: #747681;
}

.uploadsize {
    height: 109px;
    width: 109px;
    border: 1px solid #CDD6D7;
    border-radius: 22px;
    object-fit: cover;
}

.coveruploadsize {
    height: 134px;
    width: 285px;
    border: 1px solid #CDD6D7;
    border-radius: 22px;
    object-fit: cover;
}

.uploadbtn {
    border-radius: 22px;
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    color: #FFFFFF;
    border: none;
}

.heading-2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    color: var(--white);
}

.copyicon {
    position: absolute;
    margin-left: 460px;
    color: #016CE6;
    margin-top: 14px;
}

/* hover */

.coveruploadsize:hover .overlay {
    opacity: 1;
}

.ProfileOverlay {
    position: absolute;
    opacity: 0;
    transition: .5s ease;
    background-color: #ffffff69;
    height: 155px;
    width: 150px;
    border-radius: 100px;
    cursor: pointer;
}

.profilebox:hover .ProfileOverlay {
    opacity: 1;
}