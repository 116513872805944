.cardbx {
    background-color: #ffffff;
    border-radius: 12px;
    border: 1px solid var(--background);
    padding: 10px 15px;
    height: 420px;
    overflow: auto;
}

.cardHead {
    background-color: var(--background);
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    border-radius: 12px;
    padding: 10px;
}

.flowover {
    overflow-x: hidden;
    overflow-y: auto;
    height: 102px;
}

.coscard {
    background-color: #F4F4F4;
    border: var(--background) 1px solid;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 400;
}

.cos {
    font-size: 16px;
    font-weight: 500;
}
:root {
    --background: #5B93FF;
    --border: 1px solid #5C5E64;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
}