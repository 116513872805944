.page-link.active,
.active>.page-link {
    z-index: 3;
    color: var(--background) !important;
    background-color: #E3E4EB !important;
    border-color: none !important;
    border: none !important;
    background: #E3E4EB !important;
    background: #E3E4EB !important;
}

/* Details */
.main-bG {
    border: var(--background) 1px solid;
    border-radius: 12px;
    padding: 20px;
}

.profilebx {
    border-radius: 12px;
    padding: 20px;
    border: 1px dashed var(--background);
    background: #FFF;
}

.name {
    color: #000;
    font-size: 26px;
    font-weight: 400;
}

.othr {
    color: #333333;
    font-size: 16px;
    font-weight: 400;
}
.headr{
    color: #000;
    font-size: 16px;
    font-weight: 500;
}

.userprfl{
    height: 100px;
    width: 100px;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    object-fit: contain;
}
:root {
    --background: #5B93FF;
    --border: 1px solid #d2cfcfbc;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
    --blackBG: #11263aad;
}