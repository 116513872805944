.custom-file-upload {
    cursor: pointer;
    display: inline-block;
    padding: 10px 20px;
    border-radius: 8px;
    border: 1px solid var(--background);
    background: var(--whiteBG);
    color: var(--black);
    font-size: 16px;
    font-weight: 400;
}

.custom-file-upload input {
    cursor: pointer;
    display: none;
}

.dragbox {
    border-radius: 12px;
    border: 1px solid #D2D2D2;
    background: var(--whiteBG);
    padding: 20px 40px;
}

.dropBox {
    border-radius: 8px;
    border: 1px dashed var(--background);
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 10px 0;
}

.separator hr {
    flex-grow: 1;
    border: none;
    height: 1px;
    background-color: var(--black);
    margin: 0 10px;
}

.separator div {
    width: 20px;
    height: 20px;
    background-color: var(--whiteBG);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.justcsv {
    color: #F75757;
    font-size: 14px;
    font-weight: 400;
}

:root {
    --background: #5B93FF;
    --border: 1px solid #5C5E64;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
    --blackBG: #11263aad;
}