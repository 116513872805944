.recpthead {
    color: var(--black);
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.recptcashhe {
    color: var(--black);
    font-size: 18px;
    font-weight: 500;
}

.cashead {
    color: #726C6C;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.cashbtn {
    border-radius: 8px;
    border: 1px solid var(--background);
    background: var(--whiteBG);
    color: var(--background);
    font-size: 14px;
    font-weight: 500;
    padding: 10px 20px;
    cursor: pointer;
    width: 25%;
}

.categorycash {
    border-radius: 8px;
    border: 1px solid var(--background);
    background: var(--whiteBG);
    color: var(--background);
    font-size: 14px;
    font-weight: 500;
    padding: 10px 20px;
    cursor: pointer;
}
.categorycash:active {
    border-radius: 8px;
    border: 1px solid #5B93FF;
    background: #5B93FF;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 20px;
    cursor: pointer;
}
.categorycash:hover {
    background: var(--background);
    color: var(--whiteBG);

}

.disable {
    color: var(--whiteBG);
    background-color: #726C6C;
    cursor: not-allowed;
}

.datetme {
    color: var(--whiteBG);
    font-size: 14px;
    font-weight: 400;
}


::selection {
    background: #333;
    color: var(--whiteBG);
}


* {
    margin: 0;
}

.serif {
    font-family: serif;
}

.sans-serif {
    font-family: "sans-serif";
}

.bold {
    font-weight: 700;
}

.x-bold {
    font-weight: 900;
    text-shadow: 0px 0px 1px #000;
}

.hr,
.hr-sm,
.hr-lg {
    border-bottom: 1.5px dashed #333;
    margin: 10px 0;
}

.hr-sm {
    width: 30%;
    float: right;
}

.hr-lg {
    width: 100%;
}

.col2 {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.container-ticket {
    background: #e6e6e6;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    padding: 50px 0;
    box-sizing: border-box;
}

.container-ticket .ticket {
    cursor: default;
    position: relative;
    width: 300px;
    padding: 10px 20px;
    background: #fff url(https://static.licdn.com/scds/common/u/images/apps/payments/textures/texture_paper_304x128_v1.png);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.container-ticket .ticket .head-ticket {
    text-align: center;
    padding: 0px 17px;
}

.container-ticket .ticket .head-ticket p {
    font-size: 14px;
}

.container-ticket .ticket .head-ticket p:nth-child(1) {
    font-size: 18px;
}

.container-ticket .ticket .head-ticket p:nth-child(6),
.container-ticket .ticket .head-ticket p:nth-child(7) {
    font-size: 12px;
    text-align: left;
}

.container-ticket .ticket .head-ticket .code-barre {
    height: 50px;
    display: flex;
    justify-content: space-between;
    margin-left: -17px;
    margin-right: -17px;
    margin-top: 5px;
}

.container-ticket .ticket .head-ticket .code-barre span {
    height: 100%;
    width: 10px;
    display: inline-block;
    background: #333333;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(1) {
    width: 7px;
    margin-right: 2px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(2) {
    width: 5px;
    margin-right: 1px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(3) {
    width: 7px;
    margin-right: 1px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(4) {
    width: 4px;
    margin-right: 1px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(5) {
    width: 4px;
    margin-right: 2px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(6) {
    width: 5px;
    margin-right: 2px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(7) {
    width: 7px;
    margin-right: 2px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(8) {
    width: 7px;
    margin-right: 1px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(9) {
    width: 4px;
    margin-right: 2px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(10) {
    width: 6px;
    margin-right: 1px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(11) {
    width: 6px;
    margin-right: 2px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(12) {
    width: 7px;
    margin-right: 1px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(13) {
    width: 3px;
    margin-right: 2px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(14) {
    width: 3px;
    margin-right: 1px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(15) {
    width: 3px;
    margin-right: 2px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(16) {
    width: 4px;
    margin-right: 1px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(17) {
    width: 6px;
    margin-right: 1px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(18) {
    width: 6px;
    margin-right: 2px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(19) {
    width: 2px;
    margin-right: 1px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(20) {
    width: 3px;
    margin-right: 1px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(21) {
    width: 1px;
    margin-right: 2px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(22) {
    width: 3px;
    margin-right: 1px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(23) {
    width: 2px;
    margin-right: 1px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(24) {
    width: 2px;
    margin-right: 1px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(25) {
    width: 1px;
    margin-right: 1px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(26) {
    width: 4px;
    margin-right: 1px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(27) {
    width: 6px;
    margin-right: 1px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(28) {
    width: 4px;
    margin-right: 1px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(29) {
    width: 3px;
    margin-right: 1px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(30) {
    width: 1px;
    margin-right: 1px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(31) {
    width: 4px;
    margin-right: 1px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(32) {
    width: 2px;
    margin-right: 1px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(33) {
    width: 1px;
    margin-right: 2px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(34) {
    width: 2px;
    margin-right: 2px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(35) {
    width: 3px;
    margin-right: 2px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(36) {
    width: 2px;
    margin-right: 1px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(37) {
    width: 1px;
    margin-right: 2px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(38) {
    width: 1px;
    margin-right: 2px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(39) {
    width: 6px;
    margin-right: 1px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(40) {
    width: 1px;
    margin-right: 2px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(41) {
    width: 1px;
    margin-right: 2px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(42) {
    width: 5px;
    margin-right: 2px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(43) {
    width: 2px;
    margin-right: 1px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(44) {
    width: 5px;
    margin-right: 1px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(45) {
    width: 1px;
    margin-right: 1px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(46) {
    width: 1px;
    margin-right: 2px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(47) {
    width: 4px;
    margin-right: 2px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(48) {
    width: 7px;
    margin-right: 2px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(49) {
    width: 1px;
    margin-right: 2px;
}

.container-ticket .ticket .head-ticket .code-barre span:nth-child(50) {
    width: 7px;
    margin-right: 2px;
}

.container-ticket .ticket .body-ticket {
    padding: 0px 17px;
}

.container-ticket .ticket .body-ticket .produits {
    margin: 30px 0;
}

.container-ticket .ticket .body-ticket .carte {
    text-align: justify;
    text-align-last: center;
}

.container-ticket .ticket .body-ticket .carte .title-carte {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -2px;
}

.container-ticket .ticket .footer-ticket {
    padding: 0px 17px;
}

.container-ticket .ticket .footer-ticket .title-footer {
    font-size: 16px;
    font-weight: 900;
    text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.5);
    text-align: center;
    letter-spacing: 2px;
}


:root {
    --background: #5B93FF;
    --border: 1px solid #5C5E64;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
}