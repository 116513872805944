.topnav {
    border-radius: 12px;
    border: 1px solid var(--background);
    text-decoration: none !important;
}

.overslid {
    text-decoration: none !important;
}

.sidebar-text {
    font-weight: 600;
    font-size: 18px;
    color: #747681;
    text-decoration: none !important;
    cursor: pointer;
}

/* create user */
.passbx {
    background-color: rgba(217, 217, 217, 0.44999998807907104);
    border-radius: 12px;
    padding: 20px;
    display: flex;
    justify-content: center;
}

.otpbx {
    background-color: #ffffff;
    height: 40px;
    width: 40px;
    border-radius: 5px;
    border: 1px solid var(--background);
    margin: 5px;
    text-align: center;
    padding: 10px;
}

.bx {
    background-color: rgba(217, 217, 217, 0.25);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    padding: 10px;
    width: auto;
    border: 0.1px solid #dddde0;
}

:root {
    --background: #5B93FF;
    --border: 1px solid #d2cfcfbc;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
    --blackBG: #11263aad;
  }