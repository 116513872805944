/* card */
.Table-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 30px;
    align-items: stretch;
}

.tblmain {
    border-radius: 12px;
    background: rgba(207, 127, 4, 0.50);
    padding: 10px;
    cursor: pointer;
}

.tblactive {
    background: var(--background);
}

.chair {
    border-radius: 5px 5px 0px 0px;
    border-top: 1px solid #F1F1F1;
    background: #D9D9D9;
    padding: 5px 10px;
}

.chairbtm {
    border-radius: 0px 0px 5px 5px;
    border-bottom: 1px solid #F1F1F1;
    background: #D9D9D9;
    padding: 5px 10px;
}

.chairrit {
    border-radius: 0px 5px 5px 0px;
    border-right: 1px solid #F1F1F1;
    padding: 5px 10px;
    background: #D9D9D9;
}

.chairlft {
    border-radius: 5px 0px 0px 5px;
    border-left: 1px solid #F1F1F1;
    padding: 5px 10px;
    background: #D9D9D9;
}

.tablebox {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 2px solid #e29d1e;
    border-radius: 10px;
}

.tablebox.Circle {
    border-radius: 50%;
}

.tablebox.Square {
    border-radius: 0;
}

.tablebox.Oval {
    width: 150px;
    border-radius: 90px / 75px;
}

.tableboxactive {
    background: var(--background) !important;
    color: #F1F1F1;
    font-weight: 500;
}

.tableRun {
    background: var(--background) !important;
    color: #F1F1F1;
    font-weight: 500;
    opacity: 50%;
    cursor: not-allowed;
}

.chairBX {
    border: 1px solid var(--background);
    padding: 10px 20px;
    border-radius: 8px;
}

.selected {
    background-color: #ffbc3f9a;
}

.table-card.disabled {
    cursor: not-allowed;
}

.saveButton {
    border-radius: 8px;
    background: var(--background);
    border: none !important;
    color: #fff;
    padding: 10px 40px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}

/* Table Shape */
.Circle {
    border-radius: 100%;
    width: 140px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.Square {
    width: 130px;
    height: 120px;
}
.Rectangle {
    width: 190px;
    height: 110px;
}

.Oval {
    width: 150px;
    height: 90px;
    border-radius: 50%;
}

.Hexagon {
    width: 140px;
    height: 135px;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.triangle {
    text-align: center;
    width: 0;
    height: 0;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-bottom: 86.6px solid #D9D9D9;
}

.Octagon {
    width: 140px;
    height: 120px;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.Pentagon {
    width: 120px;
    height: 90px;
    clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
}

.LShaped {
    width: 250px;
    height: 150px;
    clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 25% 75%, 25% 100%, 0% 100%);
}

.KidneyShaped {
    width: 200px;
    height: 100px;
    border-radius: 50% 50% 0 0 / 100% 100% 0 0;
}

.topnav {
    border-radius: 12px;
    border: 1px solid var(--background);
    text-decoration: none !important;
}

.overslid {
    text-decoration: none !important;
}

.sidebar-text {
    font-weight: 600;
    font-size: 18px;
    color: #747681;
    text-decoration: none !important;
    cursor: pointer;
}

/* create user */
.passbx {
    background-color: rgba(217, 217, 217, 0.44999998807907104);
    border-radius: 12px;
    padding: 20px;
    display: flex;
    justify-content: center;
}

.otpbx {
    background-color: #ffffff;
    height: 40px;
    width: 40px;
    border-radius: 5px;
    border: 1px solid var(--background);
    margin: 5px;
    text-align: center;
    padding: 10px;
}

.bx {
    background-color: rgba(217, 217, 217, 0.25);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    padding: 10px;
    width: auto;
    border: 0.1px solid #dddde0;
}

.firstText {
    font-size: 16px;
    color: black;
    font-weight: bold;
}

.SecondText {
    font-size: 15px;
    color: black;
}

.borderShadow {
    box-shadow: 0px 4px 4px #e98c0a65;
    padding: 10px;
}

.heightMax {
    min-height: 110px;
}

.table-container {
    position: relative;
    /* width: 200px; */
    height: 180px;
}

.chair {
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: #e3dee2;
    box-shadow: 0px 4px 4px #b1b0afce;
    border-radius: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Adjust chair positions */
.chair-1 { top: -10px; left: 50%; transform: translate(-50%, -50%); }
.chair-2 { top: -10px; left: 25%; transform: translate(-50%, -50%); }
.chair-3 { top: -10px; left: 75%; transform: translate(-50%, -50%); }
.chair-4 { top: 25%; left: -10px; transform: translate(-50%, -50%); }
.chair-5 { top: 25%; left: 110%; transform: translate(-50%, -50%); }
.chair-6 { top: 64%; left: -15px; transform: translate(-50%, -50%); }
.chair-7 { top: 75%; left: 110%; transform: translate(-50%, -50%); }
.chair-8 { bottom: -10px; left: 50%; transform: translate(-50%, -50%); }
.chair-9 { bottom: -10px; left: 25%; transform: translate(-50%, -50%); }
.chair-10 { bottom: -10px; left: 75%; transform: translate(-50%, -50%); }
.chair-11 { top: -10px; left: 5.5%; transform: translate(-50%, -50%); }
.chair-12 { top: -10px; left: 93.5%; transform: translate(-50%, -50%); }
.chair-13 { bottom: -10px; left: 6.5%; transform: translate(-50%, -50%); }
.chair-14 { bottom: -10px; left: 87.5%; transform: translate(-50%, -50%); }
.chair-15 { top: 50%; left: -10px; transform: translate(-50%, -50%); }
.chair-16 { top: 50%; right: -10px; transform: translate(50%, -50%); }

:root {
    --background: #5B93FF;
    --border: 1px solid #5C5E64;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
}