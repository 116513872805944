.HeadBG {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: space-around;
    padding: 20px 10px;
}

.brdrbt {
    border-radius: 8px;
    border: 1px solid var(--background);
    background: #FFF;
    padding: 14px 30px;
    cursor: pointer;
    color: #000;
    font-size: 16px;
    font-weight: 400;
}

.brdrbt:hover {
    background-color: var(--background);
    border: 1px solid var(--background);
    color: #FFF;
    font-size: 16px;
    font-weight: 400;
}

.tablebG {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
}

.thead-clr {
    border-radius: 8px 8px 0px 0px !important;
    background: #E9ECFF !important;
}

.char {
    color: #000;
    font-size: 15px;
    font-weight: 500;
}

.payab {
    color: #000;
    font-size: 18px;
    font-weight: 500;
}
.voided-item {
    background-color: #ffcccc; /* or any other color you prefer */
  }
  .disabled-div {
    pointer-events: none;
    opacity: 0.5;  
}

:root {
    --background: #5B93FF;
    --border: 1px solid #5C5E64;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
}