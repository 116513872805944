/* Container Card */
.card {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    text-align: center;
    width: 550px;
    padding: 30px;
    position: relative;
}

/* Header */
.headerss {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.headerss .logos {
    font-size: 24px;
    font-weight: bold;
    color: #884b9d;
}

.headerss .inventorys {
    font-size: 16px;
    font-weight: bold;
    color: #884b9d;
}

/* Main Message */
.main-message {
    font-size: 18px;
    font-weight: bold;
    color: #000;
    margin-bottom: 15px;
}

/* Subtext */
.subtext {
    font-size: 14px;
    color: #666;
    line-height: 1.5;
    margin-bottom: 10px;
}

.subtext span {
    font-weight: bold;
    color: #000;
}

.linkss {
    font-size: 14px;
    color: #884b9d;
    font-weight: bold;
    text-decoration: none;
}

.linkss:hover {
    text-decoration: underline;
}