.meon-BG {
    border-radius: 8px;
    background: var(--whiteBG);
    box-shadow: var(--shadow);
    overflow-x: auto;
    white-space: nowrap;
}

.active {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    padding: 8px 15px;
    border-radius: 8px;
    /* background: linear-gradient(135deg, #5B93FF 38.54%, #2F4CDD 100%) !important; */
    background-color: var(--background) !important;
    color: var(--whiteBG) !important;
}

.InvActive {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    padding: 8px 15px;
    border-radius: 8px;
    background-color: var(--background) !important;
    color: var(--whiteBG) !important;
}

.overflow {
    overflow-x: auto !important;
    white-space: nowrap;

}

:root {
    --background: #5B93FF;
    --border: 1px solid #5C5E64;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
}