.dynamic-sub-menu {
  position: absolute;
  top: 6%;
  left: 12%;
  background: linear-gradient(135deg, var(--background) 38.54%, #2F4CDD 100%) !important;
  width: 200px;
  padding: 10px;
  list-style: none;
  display: none;
  z-index: 1;
}

.dynamic-sub-menu.open {
  display: block;
  margin: 20px;
}

.dynamic-sub-menu ul {
  padding: 0;
}

.dynamic-sub-menu li {
  margin-bottom: 10px;
  color: white;
  cursor: pointer;
}

.dynamic-sub-menu li:hover {
  text-decoration: underline;
}
:root {
  --background: #5B93FF;
  --border: 1px solid #5C5E64;
  --color: #5B93FF;
  --whiteBG: #fff;
  --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
  --black: #231F20;
}