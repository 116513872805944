.set-sidebar {
    color: black !important;
    height: auto !important;
    width: auto !important;
    min-width: auto !important;
    border: 1px solid #CDD6D7 !important;
    border-radius: 12px !important;
    padding: 10px;
}

.set-item-content {
    font-weight: 600;
    font-size: 18px;
    line-height: 175%;
}

.sideactive {
    color: rgb(255, 255, 255) !important;
    border: 1px solid #CDD6D7 !important;
    border-radius: 12px !important;
    background: linear-gradient(135deg, var(--background) 38.54%, #2F4CDD 100%) !important;
}

.set-sidebar .set-menu .set-menu-item>.set-inner-item:hover {
    border: 1px solid #CDD6D7;
    border-radius: 22px !important;
    color: black !important;
}

.css-dip3t8 {
    background: none !important;
}

.css-1t8x7v1>.ps-menu-button:hover {
    border-radius: 12px !important;
    background: linear-gradient(135deg, var(--background) 38.54%, #2F4CDD 100%) !important;
    color: #ffffff !important;
}

.sideBoxactive {
    color: black !important;
    border: 1px solid #CDD6D7 !important;
    border-radius: 22px !important;
}

.dashmenu {
    padding-top: 50px;
}

.sidebar-text {
    font-weight: 600;
    font-size: 18px;
    color: #747681;
}

.set-heading {
    font-weight: 500;
    font-size: 42px;
    color: var(--white)
}

:root {
    --background: #5B93FF;
    --border: 1px solid #5C5E64;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
}