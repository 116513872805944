.printhead {
    border: var(--border);
    border-radius: 12px 12px 0px 0px;
    padding: 10px;
    background-color: #fff;
}

.printhead2 {
    border: var(--border);
    border-radius: 0px 0px 12px 12px;
    background-color: #fff;
    padding: 10px;
}

.printboxIn {
    border: var(--border);
    background-color: #fff;
}

.printsupbox {
    border: var(--border);
    border-radius: 12px;
    padding: 15px;
}

.prntbtn {
    background-color: #6E5199;
    border-radius: 5px;
    padding: 5px 30px;
    color: var(--whiteBG);
    font-weight: 500;
    font-size: 14px;
    border: none;
    display: flex;
    gap: 5px;
}

.downbtn {
    border: 1px solid #6E5199;
    color: #9B9DA4;
    padding: 5px 30px;
    border-radius: 8px;
}

.mainhead {
    font-size: 24px;
    font-weight: 500;
    display: flex;
    align-items: center;
    text-align: center;
}

.headtx {
    font-size: 15px;
    font-weight: 500;
}

/* PO */
.totalhead {
    font-size: 18px;
    font-weight: 500;
}

.field {
    background-color: var(--whiteBG);
    border: var(--border);
    padding: 5px 20px;
}

.boxs {
    background-color: rgba(149, 85, 161, 0.5);
    border-top: var(--border);
    padding: 10px;
}

:root {
    /* --background: #5B93FF; */
    /* --background: #D79031; */
    --border: 1px solid #5C5E64;
    --color: #6E5199;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
}