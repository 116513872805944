.brder {
    border-radius: 12px;
    border: 1px solid #D7D7D7;
    width: fit-content;
    padding: 20px;
}

.curency {
    display: flex;
    font-size: 30px;
    font-weight: 500;
    color: #4f5778;
    align-items: center;
    padding: 12px;
    border-radius: 12px;
    text-align: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.logoImg {
    height: 84px;
    width: 84px;
    object-fit: fill;
}

.lgoimg {
    height: 85px;
    width: 85px;
    border-radius: 12px;
    object-fit: cover;
}

.income {
    color: #0F0F0F;
    font-size: 40px;
    font-weight: 600;
}

.ptyName {
    color: #3E4954;
    font-size: 16px;
    font-weight: 500;
}

.weekfilter {
    background: #F4F5F9;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.10);
    border-radius: 8px;
}

/* slider */
.gCgsXv {
    display: none !important;
}

.liNuNA {
    background-color: rgba(255, 255, 255, 0.15) !important;
    color: var(--background) !important;
    border: 1px solid var(--background) !important;
}

.blnmcK {
    background-color: rgba(255, 255, 255, 0.15) !important;
    color: var(--background) !important;
    box-shadow: 0 0 2px 0px var(--background) !important;
}

.dqnzXQ {
    display: none !important;
}

/* // XX-Large devices (larger desktops, 1400px and up) */


/* Media query for screens smaller than 600px */
@media only screen and (max-width: 600px) {
    .income {
        font-size: 22px;
    }

    .ptyName {
        font-size: 12px;
    }

    .Headtxt {
        font-size: 20px;
    }

    .headtxt2 {
        font-size: 14px;
    }

    .dnon {
        display: none;
    }

    .nav-link {
        font-size: 12px;
    }

    .mbl-flx {
        display: flex;
        flex-wrap: wrap;
    }

    .lgoimg {
        height: 60px;
    }

    .brder {
        padding: 10px;
    }
}

/* Media query for screens between 601px and 768px */
@media only screen and (min-width: 601px) and (max-width: 768px) {
    .adminprofileimgbox {
        top: 29px;
        right: 0px;
    }

    .mbl-center {
        align-items: center;
        justify-content: center;
        text-align: center;
        display: flex;
    }

    .ptsm-5 {
        padding: 10px;
    }

    .income {
        font-size: 22px;
    }

    .ptyName {
        font-size: 12px;
    }

    .mbl-flx {
        display: flex;
        flex-wrap: wrap;
    }

    .lgoimg {
        height: 60px;
    }

    .brder {
        padding: 10px;
    }
}

/* Media query for screens between 769px and 1024px */
@media only screen and (min-width: 769px) and (max-width: 992px) {
    .income {
        font-size: 15px;
    }

    .ptyName {
        font-size: 8px;
    }

    .ord-in-que {
        font-size: 14px;
    }

    .logoImg {
        height: 65px;
        width: 55px;
    }

    .summary-para {
        font-size: 14px;
    }

    .summary-head {
        font-size: 24px;
    }

    .adminprofileimgbox {
        top: 29px;
        right: 0px;
    }
}

@media only screen and (min-width: 993px) and (max-width: 1023px) {
    .income {
        font-size: 30px;
    }

    .ptyName {
        font-size: 14px;
    }
}

@media only screen and (width: 1024px) {
    .income {
        font-size: 22px;
    }

    .ptyName {
        font-size: 8px;
    }

    .brder {
        padding: 10px;
    }

    .ord-in-que {
        font-size: 12px;
    }

    .count-itself {
        font-size: 18px;
        padding: 5px 20px;
    }

    .green-mini {
        font-size: 12px;
    }

    .summary-head {
        font-size: 22px;
    }

    .summary-para {
        font-size: 14px;
    }

    .lgoimg {
        height: 50px;
        width: 50px;
        object-fit: contain;
    }
}

/* Media query for screens larger than 1200px */
@media only screen and (width: 1440px) {
    .ptyName {
        font-size: 12px;
    }

    .income {
        font-size: 30px;
    }

}

@media only screen and (min-width: 992px) and (max-width: 1279px) {}

@media only screen and (min-width:1280px) and (max-width:1368px) {
    .ptyName {
        font-size: 12px;
    }

    .income {
        font-size: 30px;
    }
}

@media only screen and (min-width:1441px) {}

@media only screen and (width: 1440px) {}

@media only screen and (width: 1024px) {}

:root {
    --background: #5B93FF;
    --border: 1px solid #5C5E64;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
}