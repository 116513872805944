.menu-item-card {
  border: var(--border);
  border-radius: 15px;
  cursor: pointer;
}

.special-discount-badge {
  position: absolute;
  background-color: var(--background);
  border-radius: 0px 8px 8px 0px;
  padding: 4px 15px;
  margin-top: 10px;
}
.addMoreBtn {
  border: 1px solid var(--background);
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  border-radius: 10px;
  padding: 15px !important;
  cursor: pointer;
}
.ProfileOverlay {
  position: absolute;
  opacity: 0;
  transition: .5s ease;
  background-color: #ffffff69;
  height: 155px;
  width: 150px;
  border-radius: 100px;
  cursor: pointer;
}

.profilebox:hover .ProfileOverlay {
  opacity: 1;
}

.special-discount-badge {
  font-size: 9px;
  color: #fff;
}

.item-pic {
  width: 100%;
  height: 240px;
  object-fit: cover;
  border-radius: 15px 15px 0px 0px;
}

.edit-button {
  background-color: var(--background);
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid var(--background);
  height: 100%;
  width: 100%;
}

.delete-button {
  background-color: #fff;
  color: #000;
  border: 1px solid var(--background);
  border-radius: 5px;
  width: 100%;
  font-size: 14px;
  padding: 12px;
}

.item-name {
  font-size: 16px;
  color: #2c2f24;
  text-align: center;
  font-weight: 500;
}

.item-des {
  color: #3f3f3f;
  font-size: 13px;
  font-weight: 200;
  text-align: center;
}

.item-price {
  color: #3f3f3f;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
}

.min-top {
  margin-top: 35px;
}

.marginL-20 {
  margin-left: 20px;
}


.absolute {
  position: absolute;
  top: -34px;
  margin-left: 50px;
}

.addItems-btn {
  justify-content: center;
  background-color: #fff;
  color: #000;
  border-radius: 10px;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  align-items: stretch;
}

.deal-image {
  width: 58px;
  height: 58px;
}

/* Media Queries */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .table td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px !important;
  }

  .table th {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px !important;
  }

  .maoin-BG {
    white-space: nowrap !important;
  }

  .maoin-BG .overflow {
    overflow-x: auto !important;
  }

  .w-40 {
    width: 85% !important;
  }

  .pt-top {
    padding-top: 75px;
  }

  .absolute {
    position: absolute;
    top: 190px;
    margin-left: 21.5%;
  }


  .Heads {
    color: #000;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
  }

  .SilverHeads {
    color: #969ba0;
    font-size: 16px;
    text-align: center;
  }

  .mbl-center {
    align-items: center;
    text-align: center;
    justify-content: center !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) and (max-width : 992px) {

  .w-40 {
    width: 75% !important;
  }

  .maoin-BG {
    white-space: nowrap !important;
  }

  .maoin-BG .overflow {
    overflow-x: auto !important;
  }

  .table td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px !important;
  }

  .table th {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px !important;
  }

  .absolute {
    margin-left: 33.5%;
  }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .absolute {
    top: 10px;
    margin-left: 5% !important;
  }

  .addItems-btn {
    font-size: 12px;
  }

  .w-40 {
    width: 50%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .item-pic {
    width: 100%;
    height: 240px;
    object-fit: cover;
  }

  .table td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px !important;
  }

  .table th {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px !important;
  }

}

@media (max-width: 1024px) and (max-height: 768px) {
  .absolute {
    top: -36px;
    margin-left: 29% !important;
  }

  .overslid {
    overflow: auto;
  }

  .overslid:hover {
    overflow: auto;
  }

  .overslid::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  .overslid::-webkit-scrollbar-thumb {
    background-color: #888;
  }

  .overslid::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
}

:root {
  --background: #5B93FF;
  --border: 1px solid #d2cfcfbc;
  --color: #5B93FF;
  --whiteBG: #fff;
  --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
  --black: #231F20;
  --blackBG: #11263aad;
}