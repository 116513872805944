.filter {
    border-radius: 8px;
    border: 1px solid #EBEBEB;
    background: #FDFDFD;
    width: 56px;
    box-shadow: 0px 4px 4px -2px rgba(209, 126, 6, 0.80);
}

.tHead {
    font-size: 18px;
    font-weight: 500;
}

.status-btn {
    padding: 10px 16px;
    align-items: center;
    border: none;
    border-radius: 10px;
    background: rgba(255, 109, 77, 0.10);
    color: #FF6D4D;
    font-weight: 500;
}

.status-delivery {
    padding: 10px 16px;
    align-items: center;
    border: none;
    border-radius: 10px;
    background: rgba(47, 76, 221, 0.04);
    color: #2F4CDD;
    font-size: 16px;
    font-weight: 500;
}

.status-delivered {
    padding: 10px 16px;
    align-items: center;
    border: none;
    border-radius: 10px;
    background: rgba(43, 193, 85, 0.05);
    color: #2BC155;
    font-size: 16px;
    font-weight: 500;
}

.table> :not(caption)>*>* {
    padding: 10px 0px 10px 0px !important;
}

.paginationPrv {
    border-radius: 6px;
    background: var(--background);
    color: #FFF;
    font-size: 18px;
    font-weight: 500;
    padding: 13px;
}

.pag {
    color: var(--background);
    font-size: 18px;
    padding: 13px 20px 13px 20px;
    font-weight: 500;
}

/* drag and Drop */

.borderbtm {
    border-bottom: 5px solid #13CF5E;
    border-radius: 30px;
}

.breaker {
    border-top: 1px solid #EFEFEF;
}

.fuTAQy {
    padding: 0px;
    border: none !important;
    border-radius: 6px;
}

.crd-btn {
    border-radius: 12px;
    background: var(--background);
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    padding: 10px;
    border: none !important;
}

.ag-header-cell-resize {
    z-index: 0 !important;
}

.orderbox {
    padding: 10px;
    background: #FFF;
    min-height: 200px;
    overflow: auto;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15000000596046448));
    border-radius: 12px;
    border: 1px solid var(--background);
}

.bmAIfI {
    padding: 20px;
    border: none !important;
    border-radius: 6px;
}

.gIxNep {
    margin-bottom: 20px;
    border-radius: 12px;
    background: var(--background);
    padding: 15px;
    color: #FFF;
    font-size: 19px;
    font-weight: 700;
}

.fEozAE {
    text-transform: none !important;
    margin-bottom: 20px;
    background: aquamarine;
    border-radius: 12px;
    background: var(--background);
    padding: 15px;
    color: #FFF;
    font-size: 19px;
    font-weight: 700;
}

.jIuOwx {
    text-transform: none !important;
    margin-bottom: 20px;
    background: aquamarine;
    border-radius: 12px;
    background: var(--background);
    padding: 15px;
    color: #FFF;
    font-size: 19px;
    font-weight: 700;
}

.irWVPi {
    padding: 20px !important;
    border-radius: 12px !important;
    background: #E9ECFF !important;
}

.eHIgBE {
    padding: 20px !important;
    border-radius: 12px !important;
    background: #E9ECFF !important;
}

.LgRxN {
    padding: 10px;
    border-radius: 12px !important;
    background: #E9ECFF !important;
}

.cardvlu {
    color: #C8C8C8 !important;
    font-size: 12px;
    font-weight: 600;
}

.selected-row {
    background-color: lightblue !important;
}

.eHIgBE {
    padding: 10px;
    border-radius: 6px;
    background: #d4d4d4;
}


.bghead {
    background: #F3F3F3;
    padding: 15px;
    border-radius: 8px;
}

.refbtn {
    border-radius: 8px;
    background: var(--background);
    padding: 10px;
    cursor: pointer;
    color: #F3F3F3;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.css-13cymwt-control {
    padding: 8px !important;
    border-radius: 8px !important;
    border: 1px solid #AbA3A3 !important;
}

.css-16xfy0z-control {
    padding: 8px !important;
    border: 1px solid #AbA3A3 !important;
    border-radius: 8px !important;
}

.css-t3ipsp-control {
    padding: 8px !important;
    border-radius: 8px !important;
}

.section-contain {
    background-color: #f4f4f4;
    border: 1px solid var(--background);
    border-radius: 12px;
    padding: 10px;
    height: 1150px;
    overflow: auto;
}

.status-contain {
    border-radius: 8px;
    background: var(--background);
    color: #FFF;
    font-size: 18px;
    font-weight: 600;
    padding: 15px;
}

.tit {
    color: #000;
    font-size: 14px;
    font-weight: 600;
}

.dsa {
    color: #616161;
    font-size: 14px;
    font-weight: 500;
}

element.style {
    grid-template-columns: 83.9531px 206.891px 196.188px 124.125px 137.453px 146.406px 83.9688px 157.891px 145.422px 169.641px 169.062px;
    grid-template-rows: repeat(1, 35px) repeat(46, 35px);
    --rdg-header-row-height: 35px;
    --rdg-summary-row-height: 35px;
    --rdg-sign: 1;
    scroll-padding-inline-start: 0px;
    scroll-padding-block: 35px 0px;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

.rnvodz57-0-0-beta-41:not(.rdg-light) {
    --rdg-color: #000;
    --rdg-border-color: #444;
    --rdg-summary-border-color: #555;
    --rdg-background-color: hsl(0, 0%, 100%);
    --rdg-header-background-color: hsl(0, 0%, 87%);
    --rdg-header-draggable-background-color: hsl(0, 0%, 100%);
    --rdg-row-hover-background-color: hsl(0, 0%, 80%);
    --rdg-row-selected-background-color: hsl(207deg 76% 42%);
    --rdg-row-selected-hover-background-color: hsl(207deg 76% 38%);
    --rdg-checkbox-color: hsl(207deg 100% 79%);
    --rdg-checkbox-focus-color: hsl(207deg 100% 89%);
    /* --rdg-checkbox-disabled-border-color: #000; */
    --rdg-checkbox-disabled-background-color: #333;
}

/* Media Queriess  Start*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

    .pad-top {
        padding-top: 100px !important;
    }

    .w-25 {
        width: 75% !important;
    }

    .table td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 5px !important;
    }

    .table th {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 5px !important;
    }

    .tHead {
        font-size: 14px;
    }

    .paginationPrv {
        font-size: 14px;
        padding: 6px;
    }

    .pag {
        font-size: 14px;
        padding: 10px 15px 6px 15px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .table td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 10px !important;
    }

    .table th {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 10px !important;
    }

    .w-25 {
        width: 85% !important;
    }

}

/* Medium devices (landscape tablets, 1024px ) */
@media screen and (max-width: 1024px) and (max-height: 1024px) {
    .DnDOvrflow {
        overflow-x: auto;
        overflow-y: hidden;
    }

    .jIuOwx {
        width: 280px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}

/* Remove unnecessary borders */
.ag-theme-alpine .ag-root-wrapper,
.ag-theme-alpine .ag-header-cell {
    border: none !important;
    color: #000000;
}

.ag-header-container {
    width: 1679px !important;
}
.ag-header-cell-text{
    color: #000000;
}
.ag-header-row-column {
    background-color: #FFFFFF;
    color: #000000;
}

/* .ag-header-row-column {
    background-color: var(--background);
    color: #fff;
} */
/* .ag-header-row-column {
    background-color: #2e9fff;
    color: #fff;
} */
/* Set global styles for the grid */
.ag-theme-alpine .ag-root-wrapper-body {
    color: #3E4954 !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    /* Increase line spacing */
}

/* Customize header cell label color */
.ag-theme-alpine .ag-header-cell-label {
    color: #3E4954 !important;
}

/* Customize odd and even row styles */
.ag-theme-alpine .ag-row {
    background-color: transparent !important;
    border: none !important;
    /* Remove row borders */
}

.ag-theme-alpine .ag-row-even {
    background-color: white !important;
    /* Set background color for even rows */
}

.ag-theme-alpine .ag-row-odd {
    background-color: white !important;
    /* Set background color for odd rows */
}

/* Set color for icons, including filter icon */
.ag-theme-alpine .ag-icon {
    color: #3E4954 !important;
}

/* Remove header and advanced filter header bottom border */
.ag-header,
.ag-advanced-filter-header {
    border-bottom: none !important;
}

/* Add styles based on colId */
.custom-cooking {
    background: rgba(255, 109, 77, 0.10) !important;
    color: #FF6D4D !important;

    display: flex;
    justify-content: center
}

.custom-delivery {
    background: rgba(47, 76, 221, 0.04) !important;
    color: #2f4cdd !important;
    font-size: 15px;
    display: flex;
    justify-content: center
}

.custom-delivered {
    background: rgba(43, 193, 85, 0.05) !important;
    color: #2bc155 !important;
    font-size: 15px;
    display: flex;
    justify-content: center
}

.custom-ReadyForDelivery {
    background-color: rgba(255, 230, 0, 0.144) !important;
    color: rgb(143, 129, 3) !important;
    font-size: 15px;
    display: flex;
    justify-content: center
}

.custom-canceled {
    background-color: rgba(126, 126, 124, 0.144) !important;
    color: rgb(73, 73, 72) !important;
    font-size: 15px;
    display: flex;
    justify-content: center
}

.ag-theme-alpine .ag-row {
    margin-bottom: 10px;
    /* Adjust the space between rows as needed */
}

.ag-theme-alpine .ag-cell {
    color: #3E4954;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    display: flex;
    align-items: center;
}

.ag-header-cell-sortable .ag-header-cell-label {
    color: #fff !important;
}

.ag-theme-alpine .ag-root-wrapper,
.ag-theme-alpine .ag-header-cell {
    color: #000000 !important;
}

.btn-border {
    border: 1px solid var(--background);
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    height: fit-content;
    align-items: center;
    color: #000;
    justify-content: center;
    width: fit-content;
    display: flex;
    border-radius: 8px;
    cursor: pointer;
}

.btn-border:hover {
    color: #fff;
    background-color: var(--background);
}

.plus-icon {
    width: 20px;
}

.searchIcon {
    position: absolute;
    padding: 14px;
}

@media (width: 1024px) {
    .rows {
        flex-direction: column;
    }

    .col6 {
        width: 100%;
    }

    .status-contain {
        font-size: 14px;
    }

    .section-contain {
        height: 100vh;
        overflow: auto;
        width: max-content;
    }

    .col4 {
        flex: 0 0 auto;
        width: 100%;
    }
}
:root {
    --background: #5B93FF;
    --border: 1px solid #5C5E64;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
}