.trdptybox {
    background-color: #FFF;
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.10));
    border-radius: 8px;
}

.dashcard {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 2px 10px 0px rgba(62, 73, 84, 0.10);
    padding: 20px;
}

/* Charts */
.ag-body-vertical-scroll {
    overflow: auto !important;
}

.ag-body-vertical-scroll-container::-webkit-scrollbar {
    width: 3px !important;
    height: 3px !important;
}

.ag-body-vertical-scroll-container::-webkit-scrollbar-thumb {
    background-color: #888 !important;
}

.ag-body-vertical-scroll-container::-webkit-scrollbar-track {
    background-color: #f1f1f1 !important;
}

.chatrtHead {
    color: #000;
    font-size: 20px;
    font-weight: 500;
}

.charthead2 {
    color: #969BA0;
    font-size: 14px;
    font-weight: 400;
}

.filterbg {
    border-radius: 6px;
    background: #F4F5F9;
    padding: 10px;
}

.fltrbtn {
    border-radius: 6px;
    background: linear-gradient(108deg, #FFF -4.95%, rgba(255, 255, 255, 0.92) 109.2%);
    border: none !important;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    padding: 8px;
}

/* item card */
.itmimg {
    border-radius: 8px;
    max-width: 50px;
    max-height: 50px;
    object-fit: cover;
}

.activecls {
    border-radius: 8px;
    border: 1px solid #5B93FF;
    background: #5B93FF;
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
}

.activecls:focus {
    color: #FFF;
}

