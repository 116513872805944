.salesprofileimg {
    position: absolute;
    top: 5px;
    right: 11px;
    border: 4px solid #fff;
    border-radius: 34px;
}
.saleProfile {
    border-radius: 8px;
    background: #6E5199;
    padding: 10px 90px 10px 15px;
    color: var(--whiteBG);
    font-size: 16px;
    font-weight: 600;
}
.saleboxicon {
    background: #dadada;
    color: #6E5199;
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 30px;
}