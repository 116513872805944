@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

.body {
  /* font-family: 'poppins-bold', sans-serif !important; */
    background-color: #F9F9F9 !important;
    font-family: 'Poppins', sans-serif;
}

body::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

body::-webkit-scrollbar-thumb {
  background-color: #888;
}

body::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.logo_size {
  height: auto;
  width: 20% !important;
}

.sider_login {
  margin: 0px;
  display: flex;
  width: 100%;
  height: 100vh;
}

.login_head {
  color: #043133 !important;
  font-size: 48px;
  font-weight: bold;
  font-family: 'poppins';
}

.Button_Normal {
  background-color: #5B93FF !important;
  color: #fff !important;
  width: 39%;
  font-size: 24px;
}

.center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
}

.align_center {
  justify-content: center !important;
  text-align: center;
}

.orange_text {
  color: #5B93FF !important;
  font-size: 14px;
  text-decoration: none;
}

.simple_mini {
  color: #4D5959;
  font-size: 14px;
}

.border_simple {
  border: 1px solid #D2D2D2;
  padding: 12px;
  border-radius: 10px;
  text-decoration: none;
}

.icons {
  height: auto;
  width: 14% !important;
  padding-right: 10px;

}

.Auth_Btn {
  color: #043133;
  font-size: 14px;
}

.head2 {
  font-size: 24px;
  color: #043133;
}

.labels {
  font-size: 14px;
}

.form-control {
  border-radius: 8px;
  border: 1px solid #AbA3A3;
  /* box-shadow: 0px 4px 4px -2px rgba(209, 126, 6, 0.80); */
  padding: 15px !important;
}

.form-check-input:checked {
  background-color: #5B93FF;
  border-color: #5B93FF;
}

.btn-secondary {
  background: #5B93FF !important;
  border: 1px solid #5B93FF !important;
}

.btn-primary {
  background: #fff !important;
  border: 1px solid #5B93FF !important;
  color: #5B93FF !important;
}

.btn-secondary:hover {
  background: #fff !important;
  border: 1px solid #5B93FF !important;
  color: #5B93FF !important;
}

.btn-primary:hover {
  background: #5B93FF !important;
  border: 1px solid #5B93FF !important;
  color: #fff !important;
}