.linefilter {
    background: #F4F5F9;
    padding: 10px;
    width: fit-content;
    border-radius: 8px;
}

.filterbtn {
    border-radius: 6px;
    background: linear-gradient(108deg, #FFF -4.95%, rgba(255, 255, 255, 0.92) 109.2%);
    color: #000;
    font-size: 14px;
    font-weight: 500;
    border: none !important;
    padding: 10px;
    cursor: pointer;
}
