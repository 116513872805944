.catCard {
    background-color: #fff;
    border: var(--background) 1px solid;
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.10));
    border-radius: 12px;
    padding: 10px;
    cursor: pointer;
}

.catCard:hover {
    border: 1px solid var(--background);
}

.cardActive {
    border: 1px solid var(--background);
}

.catImg {
    width: 60px;
    height: 60px;
    border-radius: 50px;
    object-fit: cover;
}

.catbtn {
    border-radius: 8px;
    border: 1px solid #A09999;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    padding: 10px 20px;
    width: fit-content
}

.subcat {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    padding: 10px;
}

.subcatlist {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding: 10px;
}

.CatActive {
    border-radius: 8px;
    background: linear-gradient(99deg, #D9D9D9 0%, var(--background) 0.01%, #6C76C5 100%);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    padding: 10px;
}

/* Media query for screens between 769px and 1024px */
@media only screen and (min-width: 769px) and (max-width: 992px) {}

@media only screen and (min-width: 993px) and (max-width: 1023px) {}

@media only screen and (width: 1024px) {}

/* Media query for screens larger than 1200px */
@media only screen and (width: 1440px) {}

@media only screen and (min-width: 992px) and (max-width: 1279px) {}

@media only screen and (min-width:1280px) and (max-width:1368px) {
    .card-Head {
        font-size: 12px;
    }

    .catImg {
        width: 40px;
        height: 40px;
    }

    .categorycash {
        font-size: 10px;
    }

    .AddBtn {
        font-size: 12px;
    }

    .itm_Img {
        width: 40px;
        height: 40px;
    }
}

@media only screen and (min-width:1441px) and (max-width :1600px) {
    .card-Head {
        font-size: 12px;
    }

    .catImg {
        width: 50px;
        height: 50px;
    }

    .categorycash {
        font-size: 12px;
    }

    .AddBtn {
        font-size: 12px;
    }

    .itm_Img {
        width: 50px;
        height: 50px;
    }
}

@media only screen and (min-width:1600px) and (max-width :1800px) {
    .card-Head {
        font-size: 14px;
    }

    .catImg {
        width: 50px;
        height: 50px;
    }

    .AddBtn {
        font-size: 14px;
    }

    .itm_Img {
        width: 60px;
        height: 60px;
    }
}

@media only screen and (width: 1440px) {
    .card-Head {
        color: #1E3A56;
        font-size: 12px;
        font-weight: 600;
    }
}

@media only screen and (width: 1024px) {
    .card-Head {
        font-size: 12px;
    }
}

:root {
    --background: #5B93FF;
    --border: 1px solid #d2cfcfbc;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
    --blackBG: #11263aad;
}