.quotationbox {
    background-color:rgb(217 217 217 / 8%);
    border-radius: 8px;
    border: 1px solid #5C5E64;
    min-width: 230px;
    max-width: 250px;
    height: fit-content;
}

.ovrflo {
    overflow: auto;
}

.delTalbtn {
    border: 1px solid #5C5E64;
    border-radius: 12px !important;
    background: #D79031;
    color: var(--whiteBG);
    font-size: 18px;
    font-weight: 500;
    width: -webkit-fill-available;
}

.ovrflo::-webkit-scrollbar-thumb:hover {
    background-color: #D79031;
}

:root {
    /* --background: #5B93FF; */
    /* --background: #D79031; */
    --border: 1px solid #d2cfcfbc;
    --color: #D79031;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
    --blackBG: #11263aad;
}