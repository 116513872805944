.res-card {
    padding: 15px;
    border-radius: 12px;
    border: 1px solid var(--background);
    background: #FFF;
}

.res-card:hover {
    scale: 1s;
    box-shadow: var(--shadow)
}

.res-title {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    text-align: start;
}

.res-txt {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    text-align: start;
}

.res-cardImg {
    width: 320px;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
}

.branchbox {
    border-radius: 8px;
    border: 1px solid var(--background);
    background: #FFF;
    padding: 10px 20px;
    color: #000;
    font-size: 18px;
    cursor: pointer;
    font-weight: 500;
}

.addbrnchbtn {
    border-radius: 8px;
    background: var(--background);
    padding: 10px 20px;
    color: #FFF;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}

:root {
    --background: #5B93FF;
    --border: 1px solid #d2cfcfbc;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
    --blackBG: #11263aad;
  }