.cartBG {
    border-radius: 8px 8px 0px 0px;
    background: linear-gradient(135deg, var(--background) 33.11%, #faa32a 100%);
    padding: 16px;
    color: #FFFFFF;
}

.addbtn {
    border-radius: 3px;
    background: var(--background);
    height: 17px;
    width: 20px;
    align-items: center;
    text-align: center;
    justify-content: center;
    display: flex;
    color: #FFFFFF;
    cursor: pointer;
}
:root {
    --background: #5B93FF;
    --border: 1px solid #5C5E64;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
}