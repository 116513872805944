.form-Input:disabled {
  background-color: rgb(0 0 0 / 5%) !important;
  opacity: 1 !important;
  color: #a7a7a7 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-Input {
  padding: 7px !important;
  border-radius: 5px;
  border: 1px solid #AbA3A3;
  background-color: var(--whiteBG) !important;
}

@import url('https://fonts.googleapis.com/css2?family=Itim&display=swap');

.logoName {
  font-family: 'Itim', cursive;
  color: #555;
  font-size: 24px;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.form-label {
  color: var(--black);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.28px;
}

.end {
  justify-content: end;
  display: flex;
}


.maon-BG {
  border-radius: 8px;
  background: var(--whiteBG);
  box-shadow: var(--shadow);
  padding: 10px;
}

.main-header {
  border-radius: 36px;
  background: var(--whiteBG);
  box-shadow: var(--shadow);
  padding: 10px;
}

td .css-b62m3t-container .css-13cymwt-control {
  width: 204px;
  margin: 0;
  border-radius: 0px !important;
  border: var(--border) !important;
}


td .css-b62m3t-container .css-13cymwt-control .css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

td .css-lmndiq5-menu {
  width: 220px !important;
}

td .css-b62m3t-container .css-lmndiq5-menu {
  width: 220px !important;
}

.ag-header-row-column {
  background-color: #C4C8CB !important;
  color: #000000 !important;
}

.css-10wo9uf-option:active {
  background: #6E5199 !important;
  color: #fff !important;
}

.css-10nhe30-control {
  border-color: #6E5199 !important;
  box-shadow: none !important;
}

.css-10wo9uf-option:hover {
  background: #6E5199 !important;
  color: #fff !important;
}

.css-tr4s17-option {
  background-color: #6E5199 !important;
}

.css-tr4s17-option:hover {
  background-color: rgba(0, 123, 255, 0.1) !important;
  color: #ffffff !important;
}

.ag-theme-quartz {
  --ag-header-height: 40px !important
}

.ag-theme-quartz {
  --ag-header-height: 40px !important;
  --ag-row-border-style: solid;
  --ag-row-border-width: 0.5px;
  --ag-row-border-color: #B3ADBB;
  --ag-cell-horizontal-border: solid #B3ADBB;
}


/* Media Queriess  Start*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

  .w-25 {
    width: 75% !important;
  }

  .w-75 {
    width: 100% !important;
  }

}

/* Medium devices (landscape tablets, 768px and up) */
@media screen and (min-width: 768px) and (max-width: 1024px) {

  .w-25 {
    width: 85% !important;
  }

  .w-75 {
    width: 80% !important;
  }
}

@media screen and (max-width: 1024px) and (max-height: 1024px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

  .w-75 {
    width: 84% !important;
  }
}

@media (width: 1024px) {
  .rows {
    flex-direction: column;
  }

  .col6 {
    width: 100%;
  }

  .w-25 {
    width: 45% !important;
  }

  .col4 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1279px) {}

@media only screen and (min-width:1280px) and (max-width:1440px) {
  .form-label {
    font-size: 13px;
    min-width: 93px;
  }

  .colmd-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .D-none {
    display: none;
  }
}

@media only screen and (min-width:1441px) and (max-width :1600px) {
  .form-label {
    font-size: 12px;
  }
}

@media only screen and (min-width:1600px) and (max-width :1800px) {
  .form-label {
    font-size: 12px;
  }
}

@media only screen and (width: 1024px) {
  .D-none {
    display: none;
  }

  .colmd-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .form-label {
    font-size: 13px;
    min-width: 93px;
  }
}

@media only screen and (min-width:1441px) {}

@media only screen and (width: 1440px) {}

@media only screen and (width: 1024px) {}


:root {
  /* --background: #5B93FF; */
  /* --background: #D79031; */
  --border: 1px solid #5C5E64;
  /* --color: #5B93FF; */
  --color: #D79031;
  --whiteBG: #fff;
  --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
  --black: #231F20;
}