.profilebox {
    border-radius: 8px;
    background: var(--background);
    padding: 16px 96px 16px 22px;
    color: var(--whiteBG);
    font-size: 16px;
    font-weight: 600;
}

.Headtxt {
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
}

.headtxt2 {
    color: #969BA0;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}

.profileimgbox {
    position: absolute;
    top: 11px;
    right: 31px;
    border: 4px solid var(--background);
    border-radius: 34px;
}

.locationCombo {
    position: absolute;
    right: 182px;
    border: 5px solid var(--background);
    background: none;
    border-radius: 40px;
    top: 163px;
    background-color: var(--background);
    padding: 10px;
}

.bg-body-tertiary {
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
}

.nav-active {
    border-bottom: 4px solid var(--background);
    color: var(--background) !important;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    border-radius: 4px;
}

.nav-link {
    color: var(--neutral-07, #2C2F24);
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-decoration: none;
}

/* // Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
    .profilebox {
        font-size: 14px;
        width: 120px;
        padding: 14px !important;
    }

    .adminprofileimgbox {
        top: 43px;
        right: 62px;
    }

    .pad {
        padding-right: 0px;
    }

    .adminImg {
        height: 50px;
    }

    .nav-link {
        font-size: 13px;
    }

    .active {
        padding: 5px;
    }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width : 991px) {
    .Headtxt {
        font-size: 12px;
    }

    .headtxt2 {
        font-size: 12px;
    }

    .iconBG {
        padding: 7px;
    }
}

@media (width: 1024px) {

    .Headtxt {
        font-size: 24px !important;
    }

}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {}


:root {
    --background: #5B93FF !important;
    --border: 1px solid #5C5E64;
    --color: #5B93FF !important;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
}