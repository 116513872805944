/* .container {
    margin: 0 auto;
    max-width: 1200px;
  } */
  
  /* Filters Section */
  .filters-section {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 10px 20px;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .filter-group {
    width: 22%;
  }
  
  .filter-select {
    margin-top: 5px;
  }
  
  /* Buttons */
  .search-btn {
    background-color: #000;
    color: #fff;
    margin-right: 10px;
  }
  
  .reset-btn {
    background-color: red;
    color: white;
  }
  
  /* Table */
  .active-recipe-table {
    width: 100%;
    border: 1px solid #000;
    text-align: center;
  }
  
  .active-recipe-table th,
  .active-recipe-table td {
    border: 1px solid black;
    padding: 5px;
  }
  
  .recipe-header {
    background-color: #e9d7ff;
    font-weight: bold;
  }
  
  .selling-cost-row {
    background-color: #d9ecff;
    font-weight: bold;
  }
  
  .bg-purple {
    background-color: #6c5ce7;
  }
  
  .bg-light-purple {
    background-color: #e9d7ff;
  }
  