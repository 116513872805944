/* Main container
.access-page-container {
    padding: 20px;
    font-family: Arial, sans-serif;
}

h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.tree-container {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    background-color: #fff;
}

.module-title {
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.tree-item {
    margin-left: 20px;
}

.tree-node {
    display: flex;
    align-items: center;
}
.tree-icon {
    margin-right: 8px;
    font-size: 12px;
}

.tree-label {
    font-size: 16px;
    font-weight: 500;
}

.tree-children {
    margin-left: 20px;
    border-left: 1px dashed #ccc;
    padding-left: 10px;
}

.tree-checkbox {
    margin-right: 10px;
    width: 16px;
    height: 16px;
}

.tree-checkbox-group {
    display: flex;
    align-items: center;
    margin-right: 15px;
} */




/* Use These Css */

.module-container {
    display: flex;
    gap: 20px;
}

.module-selection {
    width: 25%;
    background-color: var(--whiteBG);
    padding: 10px;
    border-radius: 0px 0px 8px 8px;
}

.module-option {
    padding: 10px 30px;
    margin: 5px 0;
    cursor: pointer;
    background-color: #e0e0e0;
    border-radius: 5px;
}

.module-option.active {
    background-color: #a0d7a0;
    text-align: left;
}

.submodule-tree {
    width: 75%;
    background-color: var(--whiteBG);
    padding: 20px;
    border-radius: 0px 0px 8px 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.module-tree {
    margin-bottom: 20px;
}

.module-title {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.submodule-list {
    padding-left: 20px;
    border-left: 2px solid #ddd;
}


.submodule-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

.checkbox-group {
    display: flex;
    gap: 10px;
}

.checkbox-item {
    display: flex;
    align-items: center;
    gap: 5px;
}

.save-button-container {
    margin-top: 20px;
    text-align: center;
}

.save-button {
    padding: 10px 20px;
    background-color: #4caf50;
    color: var(--whiteBG);
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.save-button:hover {
    background-color: #45a049;
}

:root {
    --background: #D79031;
    --border: 1px solid #5C5E64;
    --color: #D79031;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
}