.deliveryBx {
    background-color: var(--whiteBG);
    height: 287px;
    width: 100%;
    box-shadow: var(--shadow);
    border: 1px solid var(--background);
}

.imgbox {
    background-color: var(--whiteBG);
    height: 300px;
    border-radius: 15px;
    border: 1px solid var(--background);
    object-fit: cover;
    padding: 10px;
    position: absolute;
    top: -50px;
}

.notfnd {
    height: 100vh;
    width: 100%;
}

.maintx {
    color: var(--background);
    text-align: center;
    font-size: 30px;
}

.orderBtn {
    background-color: var(--background);
    border-radius: 12px;
    border: 1px solid var(--background);
    cursor: pointer;
    color: var(--whiteBG);
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
}

.findbx {
    background-color: var(--whiteBG);
    box-shadow: var(--shadow);
    border: 1px solid #beb2b2;
    padding: 15px;
}

.tx {
    color: var(--black);
    text-align: center;
    vertical-align: text-top;
    font-size: 18px;
}

.hadding {
    color: var(--background);
    text-align: center;
    font-size: 30px;
    font-weight: 500;
}

.carheader {
    color: var(--background);
    font-size: 18px;
    font-weight: bold;
}

.cardsubhead {
    color: var(--black);
    text-align: center;
    font-size: 16px;
}

.Gbox {
    background-color: var(--whiteBG);
    border-radius: 50%;
    border: 1px solid var(--black);
    padding: 30px;
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.reviewprofile {
    height: 56px;
    width: 56px;
    border: 1px solid var(--black);
    object-fit: cover;
}

.Gbox:hover {
    border: 2px solid var(--background);
    background-color: #efefef;
    box-shadow: var(--shadow);
}

.ReviewBG {
    height: 250px;
    width: 100%;
    background-color: var(--whiteBG);
    border-right: var(--background) 10px solid;
}

.choose {
    color: var(--black);
    font-size: 20px;
}

.cardtaxt {
    color: var(--black);
    font-size: 20px;
}

.viewmore {
    color: var(--background);
    background-color: var(--whiteBG);
    cursor: pointer;
    padding: 10px 30px;
    border-radius: 8px;
    width: fit-content;
}

.image-container {
    background-image: url('../assets/images/svgIcons/landingpage/landingBg.jpg');
    background-size: cover;
    background-position: center;
}

.ImageCustomer {
    background-image: url('../assets/images/CustomerBG.png');
    background-size: cover;
    background-position: center;
}

.OfferBg {
    background-image: url('../assets/images/OfferBG.png');
    background-size: cover;
    background-position: center;
}

.MessageImg {
    background-image: url('../assets/images/contactUs.png');
    height: 100%;
    width: inherit;
    border-radius: 12px;
}

.ContactHead {
    background-color: var(--background);
    color: var(--whiteBG);
    width: 100%;
    padding: 10px 20px;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    border-radius: 12px;
}

.formBox {
    background-color: var(--whiteBG);
    width: 100%;
    box-shadow: var(--shadow);
    border-radius: 12px;
    border: 1px solid var(--background);
}

.info {
    color: var(--black);
    text-align: center;
    vertical-align: text-top;
    font-size: 20px;
}

.openhor {
    color: #101a24;
    font-size: 12px;
    font-weight: bold;
}

.opntime {
    color: #5c6574;
    font-size: 16px;
    line-height: 29px;
}

:root {
    --background: #5B93FF;
    --border: 1px solid #5C5E64;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
    --blackBG: #11263aad;
}