.catlist {
    border-radius: 12px;
    border: 1px solid var(--background);
    background: #FFF;
    box-shadow: 0px 6px 12px var(--background)66;
    padding: 10px 20px;
}

.paymentlogo {
    height: 50px;
    width: 50px;
    object-fit: cover;
    border-radius: 30px;
}

.cnfrmBtn {
    color: #FFF;
    border-radius: 8px;
    border: 1px solid var(--background);
    background: var(--background);
    font-size: 18px;
    font-weight: 500;
    padding: 10px 20px;
}

.BackBtn {
    color: #FFF;
    border-radius: 8px;
    width: fit-content;
    border: 1px solid var(--background);
    background: var(--background);
    font-size: 18px;
    font-weight: 500;
    padding: 10px 20px;
    cursor: pointer;
}

.adbtn {
    border-radius: 8px;
    border: 1px solid var(--background);
    background: #F4F4F4;
    color: #231F20;
    font-size: 18px;
    font-weight: 500;
    padding: 10px 20px;
    width: 50%;
}

.curncyBox {
    border-radius: 12px;
    border: 1px solid var(--background);
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.user-shadow {
    box-shadow: 0 4px 8px rgba(209, 125, 6, 0.764);
    /* Setting the drop shadow with the color D17E06 */
    padding: 20px;
    /* Add padding to ensure the shadow is visible */
    border-radius: 10px;
    /* Optional: Add border radius for rounded corners */
}


.tblbx {
    border-radius: 8px;
    border: 1px solid #000;
    background: #FFF;
    padding: 10px 20px;
}

.edtbtn {
    border-radius: 8px;
    background: var(--background);
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 20px;
    cursor: pointer;
}

.dltbtn {
    border-radius: 8px;
    border: 1px solid var(--background);
    background: #FFF;
    color: var(--background);
    font-size: 16px;
    font-weight: 500;
    padding: 10px 20px;
    cursor: pointer;
}

.table> :not(caption)>*>* {
    padding: 10px 50px 10px 0px !important;
}

.menubx {
    background: #FFF;
    padding: 10px 20px;
    border-radius: 8px;
    position: absolute;
    z-index: 0;
    left: 190px;
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.15);
}
:root {
    --background: #5B93FF;
    --border: 1px solid #5C5E64;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
}