.nav-item {
    position: relative;
}

.subcategories-dropdown {
    display: inline-block;
    background-color: white;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
    border-radius: 0px 0px 12px 12px;
    position: absolute;
    width: fit-content;
    text-align: start;
    min-width: 120px;
    z-index: 1000; /* Ensure it appears above other content */
    top: 100%; /* Position it just below the parent element */
    left: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.4s;
}

.nav-item:hover .subcategories-dropdown,
.subcategories-dropdown-div:hover .subcategories-dropdown {
    display: block;
    visibility: visible;
    opacity: 1;
}

.subcategories-dropdown-div {
    padding: 10px 10px;
    text-decoration: none;
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 175%;
    color: black;
    cursor: pointer;
}

.subcategories-dropdown-div:hover {
    background-color: #ddd;
    border-radius: 0px;
    opacity: 1;
    transition: background-color 0.4s;
}
