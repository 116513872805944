.dropdown-content-stats {
    display: none;
    position: absolute;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
    z-index: 1;
    border-radius: 0px 0px 12px 12px;
    width: 155px;
    -webkit-transition: opacity 2s, visibility 2s;
    -moz-transition: opacity 2s, visibility 2s;
    -ms-transition: opacity 2s, visibility 2s;
    -o-transition: opacity 2s, visibility 2s;
    transition: opacity 2s, visibility 2s;
    opacity: 0;
    top: 50px;
    right: -8px;
}

.dropdown-content-stats a {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 175%;
    color: #04091E;
}

.dropdown-content-stats a:hover {
    background-color: #ddd;
    border-radius: 0px;
    transition: 0.4s ease 0s;
}

.dropdown .dropdown-content-stats {
    display: block;
    visibility: visible;
    opacity: 1;
}

.header-explore {
    background-color: transparent;
    border: none;
    color: var(--white);
    text-decoration: none;
    font-weight: 400;
    font-size: 16px;
}