.calbx {
    border-radius: 8px 0px 0px 0px;
    border: 1px solid #BAB8B4;
}

.calbx:hover {
    background-color: #5B93FF;
}

.calbx1 {
    border: 1px solid #BAB8B4;
}

.calbx1:hover {
    background-color: #5B93FF;
    color: #fff;
}

.calbx2 {
    border-radius: 0px 8px 0px 0px;
    border: 1px solid #BAB8B4;
}

.calbx2:hover {
    background-color: #5B93FF;
}

.calbx3 {
    border-radius: 0px 0px 8px 0px;
    border: 1px solid #BAB8B4;
}

.calbx3:hover {
    background-color: #5B93FF;
}

.selected-row {
    background-color: lightblue !important;
}

.calbx4 {
    border-radius: 0px 0px 0px 8px;
    border: 1px solid #BAB8B4;
}

.discountact {
    border: 1px solid rgba(0, 0, 0, 0.25);
    padding: 10px;
    width: 50%;
    border-radius: 12px 0px 0px 12px;
    font-size: medium;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
}

.disActive {
    background: aliceblue;
}

.discountact1 {
    border: 1px solid var(--black);
    padding: 10px;
    width: 50%;
    border-radius: 0px 12px 12px 0px;
    font-size: medium;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: flex;
}

.calbx4:hover {
    background-color: var(--background);
}

.caltxt {
    color: var(--black);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    display: flex;
    justify-content: center;
    padding: 10px;
}

.caltxt:hover {
    color: var(--whiteBG);
}

.cursor-pointer {
    cursor: pointer;
}

.radius {
    border-radius: 8px;
}

.disable {
    background-color: var(--background);
}

.button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

.button-container .cashbtn,
.button-container .AddBtn {
    flex-basis: calc(50% - 5px);
    margin-bottom: 10px;
}

@media (max-width: 600px) {

    .button-container .cashbtn,
    .button-container .AddBtn {
        flex-basis: calc(100% - 10px);
        /* Make buttons full width on smaller screens */
    }
}

@media (width: 1024px) {

    .itm_Img {
        width: 40px;
        height: 40px;
    }

    .catbtn {
        font-size: 12px;
        padding: 10px 10px;
    }

    .active {
        font-size: 12px !important;
        align-items: center !important;
        display: flex;
        color:#5B93FF !important;
        
    }

    .subcatlist {
        font-size: 12px;
    }

    .btn-border {
        height: auto !important;
    }

    .categorycash {
        font-size: 12px !important;
        padding: 10px 10px !important
    }

    .nav-link {
        font-size: 12px !important;
    }

    .card-Prize {
        font-size: 14px;
    }

    .AddBtn {
        font-size: 12px !important;
    }

    .cashbtn {
        font-size: 12px !important;
        padding: 10px 0px !important
    }
}

:root {
    --background: #5B93FF;
    --border: 1px solid #5C5E64;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
}