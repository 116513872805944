.pageBodr {
    border: var(--border);
    padding: 10px 20px;
    border-radius: 8px 8px 0px 0px;
}

.brdor {
    border: var(--border);
}

.arrows {
    color: var(--black);
}

.onebox {
    border: #D79031 1px solid;
    padding: 4px 10px;
    width: fit-content;
    border-radius: 5px;
    cursor: pointer;
}

.custom-row-border .ag-row {
    border-bottom: 1px solid #ddd;
}

.rmdp-input {
    padding: 25px 20px !important;
}

.onebox:hover {
    background-color: #D79031;
    color: var(--whiteBG);
}

.ag-root-wrapper {
    border-radius: 0px;
    border: none;
}
.btn
.tobox {
    background-color: #D79031;
    color: var(--whiteBG);
    padding: 4px 10px;
    width: fit-content;
    border-radius: 5px;
    cursor: pointer;
}

.pend  {
    background-color: #f44336;
    color: var(--whiteBG);
    width: 100%;
    height: 30px;
    font-size: 14px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.pend:hover {
    background-color: var(--blackBG);
    color: var(--whiteBG);
}

.aproved {
    background-color: #6E5199;
    width: 100%;
    border-radius: 30px;
    height: 30px;
    font-size: 14px;
    color: var(--whiteBG);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.aproved:hover {
    background-color: var(--blackBG);
    color: var(--whiteBG);
}

.reject {
    background-color: #DC3545;
    width: 100%;
    border-radius: 30px;
    height: 30px;
    font-size: 14px;
    color: var(--whiteBG);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.reject:hover {
    background-color: var(--blackBG);
    color: var(--whiteBG);
}

.hold {
    background-color: var(--blackBG);
    width: 100%;
    border-radius: 30px;
    height: 30px;
    font-size: 14px;
    color: var(--whiteBG);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.hold:hover {
    background-color: var(--blackBG);
    color: var(--whiteBG);
}

.repIcon {
    background: #D79031;
    color: var(--whiteBG);
    padding: 5px;
    border-radius: 6px;
}


/* Dtore Transection */
.custom-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 13px;
}

.custom-table th, .custom-table td {
    border: 1px solid #ddd;
    padding: 5px 10px;
    text-align: left;
    height: 30px; 
}

.custom-table th {
    background-color: #f8f9fa;
    font-weight: bold;
    font-size: 14px;
}

.custom-table tr:nth-child(even) {
    background-color: #f2f2f2; 
}

.close-balance-row {
    background-color: #e0f7fa;
    font-weight: bold;
}

.total-row {
    background-color: #e0f7fa;
    font-weight: bold;
}

:root {
    /* --background: #D79031; */
    --border: 1px solid #5C5E64;
    --color: #D79031;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
    --blackBG: #11263aad;
}