@media (min-width:768px) {
    body {
        padding-top: 0
    }
}

.body {
    background-color: #F9F9F9;
}

@media (min-width: 768px) {
    .sidebar.fixed-left {
        width: 150px;
        flex-flow: column nowrap;
        align-items: flex-start;
    }
}

/* Sidebar Layout */
.sidebar-layout {
    display: flex;
    height: 100vh;
    overflow: hidden;
}

.sidebar {
    width: 250px;
    transition: width 0.3s ease;
    position: sticky;
    top: 0;
    bottom: 0;
    z-index: 1;
}

.fixed-left {
    width: 250px;
}

.POS-main-content {
    flex-grow: 1;
    overflow-y: auto;
    z-index: 0;
    background-color: #FFF;
}

/* Header */
.mainBG {
    position: fixed;
    width: 100%;
    z-index: 2;
}

.mainhd {
    position: fixed;
    width: -webkit-fill-available;
    z-index: 2;
}

.ovrflo {
    overflow: auto;
}

.ovrflo:hover {
    overflow: auto;
}

.ovrflo::-webkit-scrollbar {
    width: 3px;
}

.ovrflo::-webkit-scrollbar-thumb {
    background-color: #888;
}

.ovrflo::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.logo {
    padding: 35px;
}

.navbar {
    background: #FFF;
    box-shadow: 0px 10px 40px 0px rgba(204, 208, 219, 0.50);
}


.nav-link.h6 {
    color: #3C4469;
    font-size: 10px;
    text-decoration: none;
    font-weight: 500;
}

.nav-item.a {
    border-radius: 8px;
    background: linear-gradient(135deg, #5B93FF 38.54%, #2F4CDD 100%);
}

.activetxt {
    color: #FFF;
    font-weight: 500;
    letter-spacing: 0.438px;
}

.inactivetxt {
    color: #3C4469;
    font-weight: 500;
    letter-spacing: 0.438px;
}

@media (min-width:768px) {
    .navbar.fixed-left {
        bottom: 0;
        width: 145px;
        flex-flow: column nowrap;
        align-items: flex-start;
        height: 100%;
    }

    .navbar.fixed-left .navbar-collapse {
        flex-grow: 0;
        flex-direction: column;
        width: 100%
    }

    .navbar.fixed-left .navbar-collapse .navbar-nav {
        flex-direction: column;
        width: 100%
    }

    .navbar.fixed-left .navbar-collapse .navbar-nav .nav-item {
        width: 100%
    }

    .navbar.fixed-left .navbar-collapse .navbar-nav .nav-item .dropdown-menu {
        top: 0
    }
}

@media (min-width:768px) {
    .navbar.fixed-left {
        right: auto
    }

    .navbar.fixed-left .navbar-nav .nav-item .dropdown-toggle:after {
        border-top: .3em solid transparent;
        border-left: .3em solid;
        border-bottom: .3em solid transparent;
        border-right: none;
        vertical-align: baseline
    }

    .navbar.fixed-left .navbar-nav .nav-item .dropdown-menu {
        left: 100%
    }
}


/* Media Queriess  Start*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .logo {
        padding: 0px !important;
        padding-left: 15px !important;
    }

    .sidebar-layout {
        display: list-item;
    }

    .sidebar {
        position: sticky !important;
    }

    .fixed-left {
        width: 100% !important;
    }

    .sidebar-layout {
        height: 100%;
    }

    .mainhd {
        position: sticky;
    }

    .mainBG {
        position: sticky;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}