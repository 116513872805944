.pikitm {
    border-radius: 17px;
    border: 1px solid var(--natural-300, #D0D5DD);
    width: 110px;
    height: 110px;
    object-fit: cover;
}

.itmnme {
    color: var(--natural-700, #344054);
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
}

.itmKIns {
    color: var(--natural-500, #667085);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.itmprc {
    color: var(--natural-800, #1D2939);
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
}

.itmtitl {
    color: var(--natural-500, #667085);
    font-size: 18px;
    font-weight: 500;
}

.itmmain {
    color: #000;
    font-size: 20px;
    font-weight: 400;
}

.itmpay {
    color: var(--natural-800, #1D2939);
    font-size: 18px;
    font-weight: 500;
}

.carddet {
    color: var(--natural-500, #667085);
    font-size: 14px;
    font-weight: 400;
}

@media (width: 1024px) {

    .itmprc {
        font-size: 18px;
    }

    .itmnme {
        font-size: 20px !important;
    }

    .pikitm {
        width: 80px !important;
        height: 80px !important;
    }

    .itmKIns {
        font-size: 12px !important;
    }
}