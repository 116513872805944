@media (min-width:768px) {
    body {
        padding-top: 0
    }
}

.body {
    background-color: #F9F9F9;
}

@media (min-width: 768px) {
    .sidebar.fixed-left {
        width: 150px;
        flex-flow: column nowrap;
        align-items: flex-start;
    }
}

/* Sidebar Layout */
.sidebar-layout {
    display: flex;
    height: 100vh;
    overflow: hidden;
}

.sidebar {
    width: 250px;
    transition: width 0.3s ease;
    position: sticky;
    top: 0;
    bottom: 0;
    z-index: 1;
}

.fixed-left {
    width: 250px;
}

.POS-main-content {
    flex-grow: 1;
    overflow-y: auto;
    z-index: 0;
}

/* Header */
.mainBG {
    position: fixed;
    width: 100%;
    z-index: 2;
}

.ovrflo {
    overflow: auto;
}

.ovrflo:hover {
    overflow: auto;
}

.ovrflo::-webkit-scrollbar {
    width: 3px;
}

.ovrflo::-webkit-scrollbar-thumb {
    background-color: #888;
}

.ovrflo::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}


.logo {
    padding: 22px !important;
}

.navbar {
    background: #FFF;
    box-shadow: 0px 10px 40px 0px rgba(204, 208, 219, 0.50);
}

/* 
.navbar.fixed-left {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1030
} */

.nav-link.h6 {
    color: #3C4469;
    font-size: 10px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
}

/* .nav-item.a {
    border-radius: 8px;
    background: linear-gradient(135deg, #5B93FF 38.54%, #2F4CDD 100%);
    cursor: pointer;
} */

.activetxt {
    color: #fff !important;
    font-weight: 500;
    letter-spacing: 0.438px;
}

.inactivetxt {
    color: #3C4469;
    font-weight: 500;
    letter-spacing: 0.438px;
}

.master-submenu {
    inset: auto auto 20px 150px !important;
    position: fixed !important;
    transform: translate(0px, -139px) !important;
    left: 150px !important;
    bottom: -115px !important;
}

.dropdown-toggle::after {
    display: none !important;
}

.navbar-nav .nav-item:hover .show {
    display: block;
}

.navbar-nav .nav-item .dropdown-menu {
    display: none;
}

@media (min-width:768px) {
    .navbar.fixed-left {
        bottom: 0;
        width: 145px;
        flex-flow: column nowrap;
        align-items: flex-start;
        height: 100%;
    }

    .navbar.fixed-left .navbar-collapse {
        flex-grow: 0;
        flex-direction: column;
        width: 100%
    }

    .navbar.fixed-left .navbar-collapse .navbar-nav {
        flex-direction: column;
        width: 100%
    }

    .navbar.fixed-left .navbar-collapse .navbar-nav .nav-item {
        width: 100%
    }

    .navbar.fixed-left .navbar-collapse .navbar-nav .nav-item .dropdown-menu {
        top: 0
    }
}

@media (min-width:768px) {
    .navbar.fixed-left {
        right: auto
    }

    .navbar.fixed-left .navbar-nav .nav-item .dropdown-toggle:after {
        border-top: .3em solid transparent;
        border-left: .3em solid;
        border-bottom: .3em solid transparent;
        border-right: none;
        vertical-align: baseline
    }

    .navbar.fixed-left .navbar-nav .nav-item .dropdown-menu {
        left: 100%
    }
}


/* Media Queriess  Start*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .logo {
        padding: 0px !important;
        padding-left: 15px !important;
    }

    .sidebar-layout {
        display: list-item;
    }

    .sidebar {
        position: sticky !important;
    }

    .fixed-left {
        width: 100% !important;
    }

    .POS-main-content {
        margin-left: 0px;
    }

    .sidebar-layout {
        height: 100%;
    }

    .mainBG {
        position: sticky;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .dropdown-content-ex {
        bottom: 25px !important;
    }
}


.dropdown {
    display: inline-block;
    transition: all .4s ease 3s;
}

.dropdown-content-ex {
    inset: auto auto 20px 150px !important;
    position: fixed !important;
    transform: translate(0px, -139px) !important;
    left: 140px !important;
    bottom: -115px !important;
    display: none;
    background-color: white;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
    z-index: 1;
    border-radius: 0px 0px 12px 12px;
    width: 221px;
    transition-property: transform, visibility, opacity;
    overflow-wrap: break-word;
    transition-duration: 300ms;
}

.dropdown-content-ex a {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 175%;
    color: black;
}

.dropdown-content-ex a:hover {
    background-color: #ddd;
    border-radius: 0px;
    opacity: 1;
    transition: .4s;
}

.dropdown:hover .dropdown-content-ex {
    display: block;
    visibility: visible;
    opacity: 1;
    transition: .4s;
}

.dropdown-content-ab {
    inset: auto auto 20px 150px !important;
    position: fixed !important;
    transform: translate(0px, -139px) !important;
    left: 140px !important;
    bottom: 190px !important;
    display: none;
    background-color: white;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
    z-index: 1;
    border-radius: 0px 0px 12px 12px;
    width: 221px;
    transition-property: transform, visibility, opacity;
    overflow-wrap: break-word;
    transition-duration: 300ms;
}

.dropdown-content-ab a {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 175%;
    color: black;
}

.dropdown-content-ab a:hover {
    background-color: #ddd;
    border-radius: 0px;
    opacity: 1;
    transition: .4s;
}

.dropdown:hover .dropdown-content-ab {
    display: block;
    visibility: visible;
    opacity: 1;
    transition: .4s;
}