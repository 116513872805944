.progBg {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 2px 10px 0px rgba(62, 73, 84, 0.10);
    padding: 20px;
}

.infobex {
    background-color: #ffffff;
    filter: drop-shadow(0px 4px 4px rgba(58, 57, 57, 0.755));
    border: 1px solid var(--background);
    border-radius: 13px 17px 0 0;
}

:root {
    --background: #5B93FF;
    --border: 1px solid #d2cfcfbc;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
    --blackBG: #11263aad;
}