.card-Head {
    color: #1E3A56;
    font-size: 18px;
    font-weight: 600;
}

.card-subHead {
    color: #B0CAD1;
    font-size: 16px;
}

.card-Prize {
    color: #F75757;
    font-size: 18px;
    font-weight: bold;
}

.AddCart-btn {
    color: #5B93FF;
    border: 1px solid var(--background);
    border-radius: 10px;
    font-size: 14px;
    font-weight: bold;
    width: 50%;
    cursor: pointer;
}

.AddCart-btn:hover {
    color: #fff;
    background-color: var(--background);
}

.cashcard:hover {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
    border: 0.5px solid var(--background);
    cursor: pointer;
}

.cashcard {
    border-radius: 8px;
    background: #FFF;
    padding: 20px;
    border: 2px solid #f3f3f3;
    
}

.itm_Img {
    width: 70px;
    height: 70px;
    border-radius: 50px;
    object-fit: cover;
}

:root {
    --background: #5B93FF;
    --border: 1px solid #d2cfcfbc;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
    --blackBG: #11263aad;
}