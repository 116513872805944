.custom {
    background-color: #F4F5F9;
    font-size: 14px;
    font-weight: 500;
    color: #9B9DA4;
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
}

.viebtn {
    border-radius: 12px;
    padding: 15px 30px;
    border: 1px solid var(--background);
    color: #F4F5F9;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.earn {
    background-color: #FFF;
    border: 1px solid var(--background);
    border-radius: 12px;
    padding: 20px;
}

.earntxt {
    font-weight: 500;
    color: #243465;
    font-size: 18px;
    letter-spacing: 0.27657145261764526px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
}
.modal-content{
    width: 130% !important;
}
.modal-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.modal-section {
    margin-bottom: 20px;
}

.modal-item {
    margin-bottom: 10px;
}

.receipt-container {
    font-family: Arial, sans-serif;
    color: #333;
    padding: 20px;
}

.receipt-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.receipt-logo {
    font-size: 24px;
    font-weight: bold;
}

.receipt-date {
    font-size: 14px;
}

.section-title {
    margin-top: 20px;
    font-weight: bold;
    text-align: center;
}

.receipt-row {
    display: flex;
    justify-content: space-between;
    padding: 4px 0;
}

.receipt-row.header {
    font-weight: bold;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
}

.receipt-row.total {
    font-weight: bold;
    border-top: 1px solid #ccc;
    margin-top: 10px;
    padding-top: 5px;
}

hr {
    margin: 10px 0;
}

:root {
    --background: #5B93FF;
    --border: 1px solid #5C5E64;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
}