.locationbox {
    position: absolute;
    right: 30px;
    border: 5px solid #fff;
    background: none;
    border-radius: 40px;
    top: 115px;
    background-color: var(--background);
    padding: 10px;
}

.locbox {
    border-radius: 8px;
    background: #C4C4C4;
    padding: 6px 96px 6px 22px;
}
.cartBtn{
    border-radius: 8px;
    background-color: var(--background);
    padding: 18px;
    color: #FFFFFF;
    border: none;
}
.Delivr {
    color: #3E4954;
    font-size: 14px;
    font-weight: 600;
}

.delivrloc {
    color: #3E4954;
    font-size: 12px;
    font-weight: 400;
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;
    align-items: stretch;
}

.offcanvas,
.offcanvas-xxl,
.offcanvas-xl,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm {
    --bs-offcanvas-width: 35% !important;
}

/* Media Queriess  Start*/
/* Extra small devices (phones, 600px and down) */
@media screen and (max-width: 480px) {
    .locbox {
        padding: 6px 85px 0px 20px;
    }

    .maoin-BG {
        display: flex !important;
    }

    .nav {
        flex-wrap: initial !important;
    }

    .inline {
        display: inline !important;
    }

    .pad-t {
        padding-top: 15px;
    }

    .flx-strt-mbl {
        display: flex;
        justify-content: flex-start;
    }

    .locationbox {
        right: 35px;
        top: 188px;
    }
}

@media only screen and (width: 600px) {
    .maoin-BG {
        display: flex !important;
    }

    .nav {
        flex-wrap: initial !important;
    }

    .inline {
        display: inline !important;
    }

    .pad-t {
        padding-top: 15px;
    }

    .flx-strt-mbl {
        display: flex;
        justify-content: flex-start;
    }

    .locationbox {
        right: 14px;
        top: 190px;
    }

    .locbox {
        padding: 6px 135px 6px 22px;
    }

    .w-25 {
        width: 100% !important;
    }

    .w-75 {
        width: 85% !important;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media screen and (min-width: 768px) and (max-width: 1024px) {

    .maoin-BG {
        display: flex !important;
    }

    .nav {
        flex-wrap: initial !important;
    }

    .inline {
        display: inline !important;
    }

    .pad-t {
        padding-top: 15px;
    }

    .flx-strt-mbl {
        display: flex;
        justify-content: flex-start;
    }

    .locationbox {
        right: 14px;
        top: 190px;
    }

    .locbox {
        padding: 6px 135px 6px 22px;
    }

}

/* Medium devices (landscape tablets, 1024px and up) */
@media screen and (max-width: 1024px) and (max-height: 1024px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}
:root {
    --background: #5B93FF;
    --border: 1px solid #5C5E64;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
}