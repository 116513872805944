.headtes {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.36px;
    justify-content: start;
    display: flex;
}

.headtx {
    color: var(--Body-text, #2D3748);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.28px;
}

.form-switch .form-check-input {
    height: 25px;
    width: 40px;
}

.form-check-input {
    border: 0.5px solid var(--background) !important;
}

.valtx {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.32px;
}

.imgBox {
    height: 160px;
    object-fit: cover;
    width: 160px;
    border-radius: 12px;
    border: 1px solid var(--background);
    background: rgba(217, 217, 217, 0.55);
}

.orangebx {
    cursor: pointer;
    border-radius: 12px;
    border: 1px solid var(--background);
    background: #FFF;
    padding: 90px 30px;
}

.orangebox {
    cursor: pointer;
    border-radius: 12px;
    border: 1px solid var(--background);
    background: #FFF;
    padding: 30px 30px;
}

:root {
    --background: #5B93FF;
    --border: 1px solid #d2cfcfbc;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
    --blackBG: #11263aad;
  }