.nav-tabs {
    width: 100%;
    justify-content: space-around;
}

.payment_bar {
    background: linear-gradient(135deg, var(--background) 38.54%, #2F4CDD 100%) !important;
    border-radius: 12px 12px 0px 0px;
}

.head_c {
    font-size: 18px;
    color: #fff;
    font-weight: bold;
}

.icon-size {
    width: 30px;
    height: 30px
}

.padd-h {
    margin-left: 22px;
}

.border-LR {
    border-left: 1px solid #474A52;
}

.head-active {
    color: var(--background);
}

.borderMain {
    border: 1px solid var(--background);
    border-radius: 6px;
}

.innerBorder {
    border: 1px solid #474A52;
    border-radius: 6px;
    background-color: #f4f4f4;
}

.normal-head {
    color: #474A52;
    font-size: 14px;
}

.billBtn {
    border-radius: 8px;
    background: #fff;
    border: 1px solid var(--background);
    align-items: center;
    text-align: center;
    justify-content: center;
    display: flex;
    color: var(--background);

    font-size: 14px;
    font-weight: 500;
    padding: 10px 20px;
    cursor: pointer;
    width: 25%;
}

.cashhead {
    color: var(--Text, #0A0D13);
    font-size: 15px;
    font-weight: 500;
}

.cashinvc {
    height: 35px !important;
}

.ovrflo {
    height: 100%;
    overflow: auto;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #8888886c;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--background);
}

.CashierGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;
    align-items: stretch;
}
.CategoryGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 20px;
    align-items: stretch;
}

:root {
    --background: #5B93FF;
    --border: 1px solid #d2cfcfbc;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
    --blackBG: #11263aad;
  }