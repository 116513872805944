
:root {
    /* --background: #D79031; */
    --border: 1px solid #d2cfcfbc;
    --padding: 10px 20px;
    --color: #6E5199;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
    --blackBG: #11263aad;
}

.paginationbrdr {
    background-color: #fff;
    border: 1px solid #5c5e64;
    border-radius: 0 0 8px 8px;
    padding: 5px 20px;
}
.pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.pagination-controls {
    display: flex;
    align-items: center;
}

.page-numbers {
    display: flex;
    gap: 5px;
}

.page {
    /* background-color: #e0e0e0; */
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.page.active {
    background-color: #6E5199 !important; 
    color: #fff;
}

.ellipsis {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
}

.prev, .next {
    /* background-color: #e0e0e0; */
    border: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.prev.disabled, .next.disabled {
    background-color: #f0f0f0;
    color: #b0b0b0;
    cursor: not-allowed;
}

.page-size {
    display: flex;
    align-items: center;
    gap: 20px;
}

.page-size select {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
}