.cartbutton {
    --width: 100%;
    --height: 40px;
    --tooltip-height: 35px;
    padding: 10px 20px;
    --tooltip-width: 90px;
    --gap-between-tooltip-to-button: 18px;
    --button-color: var(--background);
    --tooltip-color: var(--whiteBG);
    width: var(--width);
    height: var(--height);
    background: var(--button-color);
    position: relative;
    text-align: center;
    border-radius: 0.5em;
    font-family: "Arial";
    transition: background 0.7s;
}

.cartbutton::before {
    position: absolute;
    content: attr(data-tooltip);
    width: var(--tooltip-width);
    height: var(--tooltip-height);
    background-color: #555;
    font-size: 0.9rem;
    color: var(--whiteBG);
    border-radius: 0.25em;
    line-height: var(--tooltip-height);
    bottom: calc(var(--height) + var(--gap-between-tooltip-to-button) + 10px);
    left: calc(50% - var(--tooltip-width) / 2);
}

.cartbutton::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: #555;
    left: calc(50% - 10px);
    bottom: calc(100% + var(--gap-between-tooltip-to-button) - 10px);
}

.cartbutton::after,
.cartbutton::before {
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s;
}

.text {
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-wrapper,
.text,
.icon {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    color: var(--whiteBG);
    top: 1px;
}

.text {
    top: 0;
}

.text,
.icon {
    transition: top 0.5s;
}

.icon {
    color: var(--whiteBG);
    top: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon svg {
    width: 24px;
    height: 24px;
}

.cartbutton:hover {
    background: #222;
}

.cartbutton:hover .text {
    top: -100%;
}

.cartbutton:hover .icon {
    top: 0;
}

.cartbutton:hover:before,
.cartbutton:hover:after {
    opacity: 1;
    visibility: visible;
}

.cartbutton:hover:after {
    bottom: calc(var(--height) + var(--gap-between-tooltip-to-button) - 20px);
}

.cartbutton:hover:before {
    bottom: calc(var(--height) + var(--gap-between-tooltip-to-button));
}

:root {
    --background: #5B93FF;
    --border: 1px solid #5C5E64;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
    --blackBG: #11263aad;
}