.userprf {
    height: 100px;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    object-fit: cover;
}

/* profile */
.editbtn {
    border-radius: 8px;
    border: 1px solid var(--background);
    background: #FFF;
    color: var(--background);
    font-size: 16px;
    font-weight: 400;
    padding: 10px 20px;
    align-items: center;
    display: flex;
}

/* .editbtn:hover{
    border: none;
    background: #5b93ff;
    color: #FFF;
} */
.titlepr {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
}

.delbtn {
    border: 1px solid var(--background);
    border-radius: 8px;
    background: var(--background);
    color: #FFF;
    font-size: 18px;
    font-weight: 500;
    padding: 10px 20px;
    width: -webkit-fill-available;
}

/* img upload */

.coveruploadsize {
    height: 134px;
    width: 285px;
    border: 1px solid #CDD6D7;
    border-radius: 22px;
    object-fit: cover;
}

.coveruploadsize:hover .overlay {
    opacity: 1;
}
.padds{
    padding: 0px 120px !important;
}
:root {
    --background: #5B93FF;
    --border: 1px solid #d2cfcfbc;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
    --blackBG: #11263aad;
}