.tabllbox {
    background-color: var(--background);
    border-radius: 12px;
    padding: 10px 20px;
    color: var(--whiteBG);
}

.detail {
    border: var(--border);
    padding: 10px 20px;
}

/* receipt */
.toknbox {
    border: 1px var(--black) dotted;
    padding: 20px;
    color: var(--black);
    font-size: 18px;
    letter-spacing: 1.125px;
}



.pageBodr {
    border: 1px solid var(--background);
    padding: 10px 20px;
    border-radius: 8px 8px 0px 0px;
}

.brdor {
    border: 1px solid var(--background);
}

.arrows {
    color: var(--background);
}

.onebox {
    border: var(--background) 1px solid;
    padding: 4px 10px;
    width: fit-content;
    border-radius: 5px;
    cursor: pointer;
}

.custom-row-border .ag-row {
    border-bottom: 1px solid #ddd;
}

.rmdp-input {
    padding: 25px 20px !important;
}

.onebox:hover {
    background-color: var(--background);
    color: var(--whiteBG);
}

.ag-root-wrapper {
    border-radius: 0px;
    border: none;
}

.tobox {
    background-color: var(--background);
    color: var(--whiteBG);
    padding: 4px 10px;
    width: fit-content;
    border-radius: 5px;
    cursor: pointer;
}

:root {
    --background: #5B93FF;
    --border: 1px solid #5C5E64;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
}