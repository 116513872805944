.headbx {
  background-color: #6E5199;
  color: #fff;
  /* padding: 20px 40px; */
  padding: 10px;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  justify-content: space-around;
  border-radius: 8px 8px 0px 0px;
}

.sidebr {
  border: var(--border);
  border-radius: 0px 0px 8px 8px;
  padding: 10px;
  box-shadow: drop-shadow(0px 4px 4px rgba(0.82, 0.49, 0.02, 0.56));
}

.sidedropInActive {
  border: var(--border);
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
}

.sidedropActive {
  border: var(--border);
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  color: #fff;
  background-color: #6E5199;
}

.sidedropInActive:hover {
  background-color: #6E5199;
  cursor: pointer;
  color: #fff;
}

:root {
  /* --background: #D79031; */
  --border: 1px solid #5C5E64;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.rs-tree-node-label {
  font-size: 18px;
}