.headTxt {
    color: var(--Text, #0A0D13);
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.48px;
}

.bodytxt {
    color: var(--Text, #0A0D13);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
}
.bodytxtm {
    color: var(--Text, #0A0D13);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
}
.descrip {
    color: #ACACAC;
    font-size: 14px;
    font-weight: 400;
}

.mainclr {
    color: var(--Stroke, var(--background));
}
.mainclrm {
    color: var(--Stroke, var(--background));
}

.form-control-Payment {
    border: 1px solid #EBEBEB;
    border-radius: 4px !important;
    border: 1.5px solid var(--Stroke, var(--background)) !important;
    padding: 10px 12px 12px !important;
}

.aplybtn {
    border-radius: 4px;
    border: 1.5px solid var(--Stroke, var(--background));
    background: var(--Stroke, var(--background));
    color: var(--Text-2, #F3F3F3);
    font-size: 13px;
    font-weight: 500;
    padding: 13px 23px 13px 23px;
}

.totalprice {
    color: var(--Text, #0A0D13);
    font-size: 36px;
    font-weight: 500;
}

.itmImg {
    height: 70px;
    max-width: 70px;
    object-fit: contain;
    border-radius: 10px;
    background: #e9e9e9;
    border: 1px solid #A9A9A9;
}

.waitimg{
    width: 250px;
    object-fit: contain;
}
:root {
    --background: #5B93FF;
    --border: 1px solid #5C5E64;
    --color: #5B93FF;
    --whiteBG: #fff;
    --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
    --black: #231F20;
}